import React from 'react'
import {Searchable} from './Searchable'
import {Selectable} from './Selectable'
import {Sortable} from './Sortable'

export const TableLabel = props => {
  const {label, field, meta, store} = props

  const isSearchable = (meta.searchable || []).includes(field)
  const isSelectable = (meta.selectable || []).includes(field)
  const isSortable = (meta.sortable || []).includes(field)

  return (
    <div>
      {isSearchable && <Searchable label={label} field={field} store={store} />}
      {isSelectable && <Selectable label={label} field={field} store={store} />}
      {isSortable && <Sortable label={label} field={field} store={store} />}
      {!isSearchable && !isSelectable && !isSortable && label}
    </div>
  )
}
