import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdFormatListBulleted,
} from 'react-icons/md'
import {Link} from 'react-router-dom'

export const ReporstMenu = inject('UIStore')(
  observer(props => {
    const isOpenMenu = props.UIStore.dropdown.ReportsMenu
    const toggleReportsMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('ReportsMenu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('ReportsMenu', false)
      })
    }

    return (
      <div
        className={
          'menu horizon between stretch pl-10 pr-10 ' +
          (isOpenMenu ? 'opened-menu' : '')
        }>
        <div
          className="menu-button horizon between middle"
          onClick={toggleReportsMenu}>
          <p className="sz-14 ml-10 f-white">Reports</p>
          <MdArrowDropDown
            className={'f-white ' + (isOpenMenu ? 'hide' : 'show')}
          />
          <MdArrowDropUp
            className={'f-white ' + (isOpenMenu ? 'show' : 'hide')}
          />
        </div>
        <div
          className={
            'menu-dropdown horizon between stretch shadow-box ' +
            (isOpenMenu ? 'show' : 'hide')
          }>
          <div className="links vertical left middle">
            <Link to="/ppc-details" onClick={toggleReportsMenu}>
              <MdFormatListBulleted />
              <span>All PPC Ads</span>
            </Link>
          </div>
        </div>
      </div>
    )
  })
)
