import {DateTime} from 'components'
import {MoreLess} from 'materials'
import {UsageHistoryModal} from 'pages/PromoCode/UsageHistoryModal'
import React, {useState} from 'react'
import {GiTicket, MdCheck, MdClose} from 'react-icons/all'

export const Row = props => {
  const {data: i} = props
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(!show)

  return (
    <tr>
      <td>
        {show && (
          <UsageHistoryModal
            onClose={handleShow}
            title={
              <div className="horizon middle">
                <GiTicket className="mr-10" />
                <span className="mr-20">{i.code} </span>
                <a
                  href={'//' + i.merchant}
                  target="_blank"
                  rel="noreferrer noopener">
                  <MoreLess content={i.merchant} maxLimit="30" />
                </a>
              </div>
            }
            website={i.merchant}
            code={i.code}
          />
        )}
        <a href={'//' + i.merchant} target="_blank" rel="noreferrer noopener">
          <MoreLess content={i.merchant} maxLimit="30" />
        </a>
      </td>
      <td>
        <MoreLess content={i.code} maxLimit="30" />
      </td>
      <td>
        <button className="btn basic p-0" onClick={handleShow}>
          {i.usage_count}
        </button>
      </td>
      <td>
        <DateTime
          timestamp={i.last_used_time}
          format={'MMM D YYYY | hh:mm A'}
        />
      </td>
      <td>{i.total_discount === '$0' ? '-' : i.total_discount}</td>
      <td>{i.average_discount === '$0' ? '-' : i.average_discount}</td>
      <td>{i.average_basket === '$0' ? '-' : i.average_basket}</td>
      <td>
        {i.worked ? (
          <MdCheck className="status active" />
        ) : (
          <MdClose className="status inactive" />
        )}
      </td>
    </tr>
  )
}
