import React from 'react'

export const EmptyBoard = props => {
  return (
    <div className="per-100 empty-board vertical center middle">
      <div className="icon">
        <div className="circle">{props.icon}</div>
      </div>
      <div className="content vertical center middle">
        <h2>{props.title}</h2>
        <p>{props.description}</p>
        {props.button || null}
      </div>
    </div>
  )
}
