import {AuthStore} from './AuthStore'
import {SystemMessageStore} from './SystemMessageStore'
import {UIStore} from './UIStore'

import ProfileStore from './ProfileStore'
import {default as HistoryStore} from './HistoryStore'
import {default as PromoStore} from './PromoStore'
import {default as RuleStore} from './RuleStore'
import TransactionStore from './TransactionStore'
import UserStore from './UserStore'
import ConversionStore from './ConversionStore'
import ActionLogStore from './ActionLogStore'
import MetaStatsStore from './MetaStatsStore'

class RootStore {
  constructor() {
    this.UIStore = new UIStore(this)
    this.SystemMessageStore = new SystemMessageStore(this)
    this.AuthStore = new AuthStore(this)
    this.ProfileStore = new ProfileStore(this)
    this.UserStore = new UserStore(this)
    this.TransactionStore = new TransactionStore(this)
    this.HistoryStore = new HistoryStore(this)
    this.PromoStore = new PromoStore(this)
    this.RuleStore = new RuleStore(this)
    this.ConversionStore = new ConversionStore(this)
    this.ActionLogStore = new ActionLogStore(this)
    this.MetaStatsStore = new MetaStatsStore(this)
  }
}

export const stores = new RootStore()
