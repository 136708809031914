import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdClose} from 'react-icons/md'

export const SystemMessages = inject('SystemMessageStore')(
  observer(props => {
    const messages = props.SystemMessageStore.news
    return (
      <div id="systemMessages">
        {messages.map((message, key) => {
          const className =
            message.status >= 400
              ? 'error'
              : message.status >= 300
              ? 'warn'
              : message.status >= 200
              ? 'success'
              : 'success'
          return (
            <div key={key} className={'horizon center per-100 ' + className}>
              <div
                className={
                  'alert-box ' + className + ' per-50 between system-messages'
                }>
                <p>{message.message}</p>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={message.hide}>
                  <MdClose />
                </button>
              </div>
              {message.hideTimeout()}
            </div>
          )
        })}
      </div>
    )
  })
)
