import {EmptyBoard, MoreLess} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React from 'react'
import {RiStickyNote2Line} from 'react-icons/all'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {DateTime} from '../'
import {Loader} from '../Loader'

export const BrowsingHistory = inject(
  'UIStore',
  'HistoryStore',
  'ProfileStore'
)(
  observer(props => {
    const history = useHistory()
    const {country} = useParams()
    const {id} = props
    const {search} = useLocation()
    const params = qs.parse(search)
    if (!params.type) params.type = 'generic'

    const {today, yesterday} = props.ProfileStore
    const {item, detailState} = props.HistoryStore

    const from = new Date(
      params.from || props.UIStore.from || yesterday.startOf('days').toDate()
    )
    const to = new Date(
      params.to || props.UIStore.to || today.endOf('days').toDate()
    )

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      props.HistoryStore.read({
        params: {
          ...params,
          search: JSON.stringify([{field: 'user_id', q: [id], include: true}]),
          from: startTime,
          to: endTime,
        },
        onModal: true,
      })
    }, [props.HistoryStore, id, from, to])

    const affiliatedList = item.filter(i => i.visitType !== 'not_affiliate')
    const nonaffiliatedList = item.filter(i => i.visitType === 'not_affiliate')

    const defaultIndex = affiliatedList.length ? 0 : 1

    return (
      <div className="p-10 vertical border-box">
        <React.Fragment>
          {detailState === 'getting' ? (
            <div className="mb-40 mt-20">
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              {item.length ? (
                <Tabs
                  className="vertical left stretch"
                  defaultIndex={defaultIndex}>
                  <TabList
                    className="tab-menu item switch-style"
                    default={true}>
                    <Tab className="per-50">
                      <span className="affiliated horizon between middle">
                        <p>Affiliated</p>
                        <span>{affiliatedList.length}</span>
                      </span>
                    </Tab>
                    <Tab className="per-50">
                      <span className="non-affiliated horizon between middle">
                        <p>Nonaffiliated</p>
                        <span>{nonaffiliatedList.length}</span>
                      </span>
                    </Tab>
                  </TabList>
                  <TabPanel className="per-100">
                    <div
                      id="scrollBar1"
                      className="horizon between stretch per-100 modal-scroll-body mt-10">
                      {affiliatedList.length ? (
                        <table className="list-table-modal border-box per-100">
                          <thead>
                            <tr>
                              <td>Date Time</td>
                              <td>Web Site</td>
                              <td>Affiliated</td>
                            </tr>
                          </thead>
                          <tbody>
                            {affiliatedList.map(i => (
                              <tr key={'history-modal-' + i.id}>
                                <td>
                                  <DateTime
                                    timestamp={i.last_visit}
                                    format={'MMM D YYYY | hh:mm A'}
                                  />
                                </td>
                                <td>
                                  <a
                                    href={'//' + i.domain}
                                    target="_blank"
                                    rel="noreferrer noopener">
                                    <MoreLess
                                      content={i.domain}
                                      maxLimit="30"
                                    />
                                  </a>
                                </td>
                                <td>YES - {i.visitType}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="per-100 vertical center middle">
                          <EmptyBoard
                            icon={<RiStickyNote2Line />}
                            description="There is no affiliated history for this user"
                          />
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel className="per-100">
                    <div
                      id="scrollBar2"
                      className="horizon between stretch per-100 modal-scroll-body mt-10">
                      {nonaffiliatedList.length ? (
                        <table className="list-table-modal border-box per-100">
                          <thead>
                            <tr>
                              <td>Date Time</td>
                              <td>Web Site</td>
                              <td>Affiliated</td>
                            </tr>
                          </thead>
                          <tbody>
                            {nonaffiliatedList?.map(i => (
                              <tr key={'history-modal-' + i.id}>
                                <td>
                                  <DateTime
                                    timestamp={i.timestamp}
                                    format={'MMM D YYYY | hh:mm A'}
                                  />
                                </td>
                                <td>
                                  <a
                                    href={'//' + i.current_url}
                                    target="_blank"
                                    rel="noreferrer noopener">
                                    <MoreLess
                                      content={i.current_url}
                                      maxLimit="30"
                                    />
                                  </a>
                                </td>
                                <td>NO - {i.visitType}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="per-100 vertical center middle">
                          <EmptyBoard
                            icon={<RiStickyNote2Line />}
                            description="There is no affiliated history for this user"
                          />
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              ) : (
                <div className="per-100 vertical center middle">
                  <EmptyBoard
                    icon={<RiStickyNote2Line />}
                    description="There is no affiliated history for this user"
                  />
                </div>
              )}
              {nonaffiliatedList.length > 0 && (
                <button
                  className="btn small submit mt-10"
                  name="SEE ALL"
                  onClick={() => {
                    props.UIStore.modalClose()
                    history.push({
                      pathname: `/${country}/browsing-history`,
                      search: '?user_id=' + id,
                    })
                  }}>
                  {' '}
                  See All{' '}
                </button>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    )
  })
)
