import React from 'react'
import {MoreLess} from 'materials'

export const WebsiteOpener = props => {
  const {url, limit} = props
  const startIndex = (url || '').indexOf('//')
  const showUrl =
    startIndex !== -1
      ? url.slice(startIndex + 2).replace('www.', '')
      : (url || '').replace('www.', '')

  return (
    <React.Fragment>
      {url ? (
        <a
          className="sz-12 "
          href={url && (url.startsWith('http') ? url : '//' + url)}
          target="_blank"
          rel="noreferrer noopener">
          <MoreLess content={showUrl} maxLimit={limit} />
        </a>
      ) : (
        <span>-</span>
      )}
    </React.Fragment>
  )
}
