import React, {Component} from 'react'

export class SecondaryButton extends Component {
  render() {
    return (
      <button
        onClick={this.props.onClick}
        data-id={this.props.id}
        className={'btn secondary ' + this.props.className}>
        {this.props.children}
        {this.props.name}
      </button>
    )
  }
}
