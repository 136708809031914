import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export const TimePicker = inject('UIStore')(
  observer(props => {
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search)
    const [selected, setSelected] = useState(
      searchParams.get('time') || props.UIStore.time
    )

    const handleChange = e => {
      const time = e.currentTarget.value
      searchParams.set('time', time)
      searchParams.delete('page')
      props.UIStore.setTime(time)
      setSelected(e.currentTarget.value)
      history.push('?' + searchParams)
    }

    return (
      <form className="switch-time-range">
        <label className="item">
          <input
            type="checkbox"
            value="day"
            checked={selected === 'day'}
            onChange={handleChange}
          />
          <div className="button">
            <p>Today</p>
          </div>
        </label>
        <label className="item">
          <input
            type="checkbox"
            value="week"
            checked={selected === 'week'}
            onChange={handleChange}
          />
          <div className="button">
            <p>This Week</p>
          </div>
        </label>
        <label className="item">
          <input
            type="checkbox"
            value="month"
            checked={selected === 'month'}
            onChange={handleChange}
          />
          <div className="button">
            <p>This Month</p>
          </div>
        </label>
      </form>
    )
  })
)
