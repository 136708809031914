import {LayoutDefault, LayoutGuest} from 'layouts'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {
  ActionLogs,
  BrowsingHistory,
  Conversions,
  ExtensionInstalls as Dashboard,
  Logout,
  NoMatch,
  PromoCode,
  RedirectTo,
  Rules,
  Transactions,
} from './pages'

const RestrictedRoute = ({
  component: Component,
  layout: Layout = LayoutDefault,
  authenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
              state: {from: props.location},
            }}
          />
        )
      }
    />
  )
}

const GuestRoute = ({
  component: Component,
  layout: Layout = LayoutGuest,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}

//FIXME this components cannot have hoc or React.Fragment
const onlyAdminRoutes = ({authenticated, me}) => [
  <RestrictedRoute
    exact
    path="/:country/dashboard"
    layout={LayoutDefault}
    component={Dashboard}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/extension"
    layout={LayoutDefault}
    component={Dashboard}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/transactions"
    layout={LayoutDefault}
    component={Transactions}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/browsing-history/:id?"
    layout={LayoutDefault}
    component={BrowsingHistory}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/promo-code"
    layout={LayoutDefault}
    component={PromoCode}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/conversions"
    layout={LayoutDefault}
    component={Conversions}
    authenticated={authenticated}
    me={me}
  />,
  <RestrictedRoute
    exact
    path="/:country/action-logs"
    layout={LayoutDefault}
    component={ActionLogs}
    authenticated={authenticated}
    me={me}
  />,
]

export const Routing = inject(
  'UIStore',
  'AuthStore'
)(
  observer(props => {
    const {authenticated, me} = props.AuthStore

    // TODO IS ADMIN CONTROL REQUIRED BEACUSE THIS PROJECT HAS NO CLIENT SIDE
    const is_admin = me && me.checkProjectAccess() === 'ADMIN'
    return (
      <Switch>
        <GuestRoute path="/logout" component={Logout} layout={LayoutDefault} />

        {is_admin ? onlyAdminRoutes({authenticated, me}) : null}

        <RestrictedRoute
          exact
          path="/:country"
          component={is_admin ? Dashboard : Rules}
          layout={LayoutDefault}
          authenticated={authenticated}
          me={me}
        />

        <RestrictedRoute
          exact
          path="/:country/rules/:page(add|edit)?/:id?"
          layout={LayoutDefault}
          component={Rules}
          authenticated={authenticated}
          me={me}
        />

        <GuestRoute
          path="/:country/**"
          component={NoMatch}
          layout={LayoutDefault}
          me={me}
        />

        <GuestRoute component={RedirectTo} layout={LayoutDefault} />
      </Switch>
    )
  })
)
