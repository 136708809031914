import {CustomInput} from 'form'
import {useOutsideAlerter} from 'helpers'
import * as get from 'lodash.get'
import React, {useMemo, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {
  FiMinus,
  GoPlus,
  MdArrowDropDown,
  MdArrowDropUp,
  MdClose,
} from 'react-icons/all'
import {VariableSizeList} from 'react-window'
import useDeepCompareEffect from 'use-deep-compare-effect'

const ICON_STYLE = {fontSize: 15, marginRight: 10}
const ONE_ITEM_HEIGHT = 40

const ItemRenderer = ({index, data, style}) => {
  const {
    options,
    name,
    multi,
    closeList,
    getValues,
    setValue,
    propsOnChange,
    searchRef,
  } = data
  const {label, value} = options[index]
  const values = getValues(name)

  const isSelected = multi
    ? (values || []).includes(value)
    : (values || '') === value
  return (
    <label
      className={
        'horizon between evet-select-list-item ' + (isSelected && ' selected ')
      }
      style={style}>
      <span>{label}</span>
      {
        <input
          className={multi ? '' : 'hide'}
          type={multi ? 'checkbox' : 'radio'}
          value={value}
          name={name}
          checked={isSelected}
          onChange={() => {
            const newValue = multi
              ? isSelected
                ? (values || []).filter(i => i !== value)
                : [...(values || []), value]
              : value
            setValue(
              name,
              !multi
                ? newValue
                : newValue.sort(
                    (a, b) =>
                      options.map(i => i.value).indexOf(a) -
                      options.map(i => i.value).indexOf(b)
                  ),
              {shouldValidate: true, shouldDirty: true}
            )
            propsOnChange(
              !multi
                ? newValue
                : newValue.sort(
                    (a, b) =>
                      options.map(i => i.value).indexOf(a) -
                      options.map(i => i.value).indexOf(b)
                  )
            )
            if (multi && searchRef.current) searchRef.current.focus()
            else closeList()
          }}
        />
      }
    </label>
  )
}

export const CustomSelect = ({
  name = 'checkbox',
  label = '',
  placeholder = 'Select...',
  options = [...Array(10000)].map((u, i) => ({label: 'option ' + i, value: i})),
  onChange: propsOnChange = () => null,

  multi = false,
  dropdownType = 'absolute',

  wrap,
  disableSearch = (options || []).length < 15,
  listHeight,
  disableIcon = false,
  iconLocation = 'right',
  openIcon = dropdownType === 'absolute' ? (
    <MdArrowDropUp style={ICON_STYLE} />
  ) : (
    <FiMinus style={ICON_STYLE} />
  ),
  closeIcon = dropdownType === 'absolute' ? (
    <MdArrowDropDown style={ICON_STYLE} />
  ) : (
    <GoPlus style={ICON_STYLE} />
  ),
  ...props
}) => {
  const wrapperRef = useRef(null)
  const searchRef = useRef(null)
  const [open, setOpen] = useState(false)

  const scrollHeight = useMemo(() => {
    const scrollMaxLen = (options || []).length * ONE_ITEM_HEIGHT
    return dropdownType === 'alwaysOpen'
      ? Math.min(listHeight || 185, scrollMaxLen)
      : Math.min(listHeight || 305, scrollMaxLen)
  }, [options, dropdownType, listHeight])

  const getOptions = useMemo(() => {
    return options.map(i => ({
      label: i.label.toString() || i.label.toString(),
      labelLowerCase: (i.label.toString() || i.label.toString()).toLowerCase(),
      value: i.value.toString(),
    }))

    // todo we might want to sort for selected checkboxes
    // .sort(
    //   (b, a) =>
    //     selectedValue.includes(a.value) - selectedValue.includes(b.value)
    // )
  }, [options])

  const {
    register,
    getValues,
    setValue,
    watch,
    defaultValues,
    onSubmit,
    submitOnChange,
    errors,
  } = useFormContext() || {}
  const error = get(errors, name)
  const defaultValue = get(defaultValues, name)
  const watchValues = watch()
  const selectedValue = multi
    ? get(watchValues, name) || []
    : get(watchValues, name) || ''
  const selectedItem = multi
    ? false
    : getOptions.find(i => i.value === selectedValue)

  const [filteredOptions, setFilteredOptions] = useState(getOptions)

  const closeList = () => {
    if (open) {
      setOpen(false)
      if (searchRef.current) searchRef.current.value = ''
      setFilteredOptions(getOptions)
    }
  }

  const clear = async e => {
    e.stopPropagation()
    setValue(name, multi ? [] : '', {shouldDirty: true})
    propsOnChange(multi ? [] : '')
    if (submitOnChange) onSubmit()
  }

  useOutsideAlerter(wrapperRef, closeList)

  useDeepCompareEffect(() => {
    setFilteredOptions(getOptions)
  }, [options])

  useDeepCompareEffect(() => {
    register({name, value: defaultValue ? defaultValue : multi ? [] : ''})
  }, [register, name, defaultValue, multi])

  const handleShowList = () => {
    const newState = !open
    setOpen(newState)
    setTimeout(() => {
      if (newState && searchRef.current) searchRef.current.focus()
      else {
        if (searchRef.current) searchRef.current.value = ''
        setFilteredOptions(getOptions)
      }
    }, 100)
  }

  const handleSearch = e => {
    const value = e.currentTarget.value
    setFilteredOptions(
      value
        ? getOptions.filter(i => i.labelLowerCase.includes(value))
        : getOptions
    )
  }

  return (
    <div
      ref={wrapperRef}
      className={`evet-select-wrapper ${wrap && ' section '}  ${
        props.className
      }`}>
      {label && <span className="mb-6">{label}</span>}
      {dropdownType !== 'alwaysOpen' && (
        <div
          className={
            'evet-select-header horizon center middle per-100 ' +
            (open && ' opened ')
          }
          onClick={handleShowList}>
          <div className="evet-select-label horizon">
            {!disableIcon &&
              iconLocation === 'left' &&
              (open ? openIcon : closeIcon)}
            <span>
              {multi
                ? selectedValue.length
                  ? selectedValue.length + ' Item Selected'
                  : placeholder
                : (selectedItem || {}).label || placeholder}
            </span>
          </div>
          <div className="horizon">
            {(multi ? selectedValue.length : selectedValue) ? (
              <MdClose style={ICON_STYLE} onClick={clear} />
            ) : null}
            {!disableIcon &&
              iconLocation === 'right' &&
              (open ? openIcon : closeIcon)}
          </div>
        </div>
      )}

      <div
        role="list"
        className={
          'evet-select-list ' +
          (dropdownType === 'alwaysOpen'
            ? 'always-open '
            : dropdownType + ' ' + (!open && ' all-hide '))
        }>
        {!disableSearch && (
          <div className="evet-select-list-search-bar">
            <CustomInput
              inputProps={{ref: searchRef}}
              icon="search"
              onChange={handleSearch}
              className={
                dropdownType !== 'alwaysOpen' ? 'per-100 ' : 'per-100 no-border'
              }
              placeholder="Search item"
            />
          </div>
        )}

        <div className="evet-select-scroll-list">
          <VariableSizeList
            itemData={{
              options: filteredOptions,
              multi,
              name,
              closeList,
              getValues,
              setValue,
              propsOnChange,
              searchRef,
            }}
            itemKey={index => filteredOptions[index].value}
            height={scrollHeight}
            itemCount={filteredOptions.length}
            itemSize={() => ONE_ITEM_HEIGHT}
            overscanCount={2} //The number of items (rows or columns) to render outside of the visible area
          >
            {ItemRenderer}
          </VariableSizeList>
        </div>
      </div>
      {error && <p className="c-alert pt-6 sz-12">{error.message}</p>}
    </div>
  )
}
