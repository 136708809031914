import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import logo from 'images/favicon.png'
import {CustomHeader, SystemMessages, Modal} from 'components'
import '@evet/library/dist/styles.css'
import './assets/App.scss'
import {version} from '../package.json'
import {EvetButton, Layouts, ProductSwitch, Version} from '@evet/library'
const {Layout, Aside, Project, Page} = Layouts

export const LayoutDefault = props => {
  const {me = {products: []}} = props
  return (
    <React.Fragment>
      <SystemMessages />
      <Modal />
      <Layout title={props.title} description={props.description}>
        <Aside>
          <EvetButton logo={logo} me={me} />
          <ProductSwitch
            products={me.products}
            product={me.product}
            env={process.env.NODE_ENV}
          />
        </Aside>
        <Project>
          <CustomHeader />
          <Page>{props.children}</Page>
        </Project>
        <Version version={version} />
      </Layout>
    </React.Fragment>
  )
}

// export const LayoutNoHeader = props => (
//   <React.Fragment>
//     <SystemMessages />
//     <Layout title={props.title} description={props.description}>
//       <Project>
//         <Page>{props.children}</Page>
//       </Project>
//     </Layout>
//   </React.Fragment>
// )

export const LayoutGuest = props => (
  <React.Fragment>
    <SystemMessages />
    <Layout title={props.title} description={props.description}>
      <Project>
        <CustomHeader />
        <Page>{props.children}</Page>
      </Project>
    </Layout>
  </React.Fragment>
)
