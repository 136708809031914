import {yupResolver} from '@hookform/resolvers/yup'
import React, {useRef, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as Yup from 'yup'

export function Form({
  className,
  defaultValues = {},
  children,
  schema = Yup.object().shape({}),
  onSubmit = () => null,
  onChange,
  submitOnChange,
  enter = false,
}) {
  const [initialized, setInitialized] = useState(false)
  const {handleSubmit, ...methods} = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
  })
  const watch = {...methods.watch()}
  const formRef = useRef(null)
  if (onChange) onChange(watch)

  // console.log("form-form", methods.watch(), methods.errors);
  useDeepCompareEffect(() => {
    if (!initialized) {
      setInitialized(true)
    } else if (defaultValues) {
      setInitialized(true)
      methods.reset(defaultValues)
    }
  }, [defaultValues])

  const handleFormChange = () => {
    // e.preventDefault()
    if (submitOnChange) {
      handleSubmit(onSubmit)()
    }
  }

  return (
    <FormProvider
      {...methods}
      submitOnChange={submitOnChange}
      defaultValues={defaultValues}
      onSubmit={handleSubmit(onSubmit)}>
      <form
        onKeyPress={e => enter && e.key === 'Enter' && e.preventDefault()}
        ref={formRef}
        className={className}
        onSubmit={handleSubmit(onSubmit)}
        onChange={handleFormChange}
        // value={formValue}
      >
        {children}
      </form>
    </FormProvider>
  )
}
