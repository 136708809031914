import {request} from '../helpers'

export class Services {
  static couponTransactions = async ({params}) => {
    return await request.get('/stats/coupon-transactions', params, true)
  }

  static websiteTransactions = async ({params}) => {
    return await request.get('/stats/website-transactions', params, true)
  }
}
