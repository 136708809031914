export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('promo meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Rule {
  created_at
  domain
  id
  type
  rule_pairs

  constructor(data) {
    // created_at: "2020-07-28"
    // domain: "westpointhome.com"
    // id: {$oid: "5f2034ab4fee68fb44fb227c"}
    // type: "shopify"
    if (data) {
      this.created_at = data.created_at
      this.domain = data.domain
      this.id = (data.id || data._id || {}).$oid
      this.type = data.type
      this.rule_pairs = data.rule_pairs
    }
    // if (data)
    // 	Object.entries(data).forEach(([k, i]) => this[k] = i)
  }

  // get id() {
  // 	return `${this.merchant}_${this['code']}`
  // }
}
