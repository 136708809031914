import {action, computed, makeObservable, observable} from 'mobx'
import {MetaStats as Model} from 'models'
import {MetaStatsServices as Service} from 'services'

export default class Store {
  _couponTransactions = new observable.map()
  _websiteTransactions = new observable.map()

  couponTransactionsState = 'init'
  websiteTransactionsState = 'init'

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _couponTransactions: observable,
      _websiteTransactions: observable,

      couponTransactionsState: observable,
      websiteTransactionsState: observable,

      couponTransactions: computed,
      websiteTransactions: computed,

      readCouponTransactions: action,
      readWebsiteTransactions: action,

      handleError: action.bound,
    })
  }

  get couponTransactions() {
    return [...this._couponTransactions.values()]
  }

  get websiteTransactions() {
    return [...this._websiteTransactions.values()]
  }

  readCouponTransactions({params = {}}) {
    //if (this.couponTransactionsState === 'init')
    Service.couponTransactions({params}).then(res => {
      this.couponTransactionsState = 'done'
      if (res.data) {
        res.data.forEach((i, j) => {
          this._couponTransactions.set(j, new Model(i || {}))
        })
      }
    }, this.handleError)
  }

  readWebsiteTransactions({params = {}}) {
    //if (this.websiteTransactionsState === 'init')
    Service.websiteTransactions({params}).then(res => {
      this.websiteTransactionsState = 'done'
      if (res.data) {
        res.data.forEach((i, j) => {
          this._websiteTransactions.set(j, new Model(i || {}))
        })
      }
    }, this.handleError)
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
