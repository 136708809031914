import {inject, observer} from 'mobx-react'
import React from 'react'
import {Map, Marker, TileLayer} from 'react-leaflet'
// import leaflet from "leaflet";
import {Loader} from '../Loader'

require('leaflet-control-geocoder')

export const ShowOnMap = inject('UserStore')(
  observer(props => {
    const {item, detailState} = props.UserStore
    // const map = this.leafletMap.leafletElement;
    // const geocoder = leaflet.Control.Geocoder.nominatim();

    // if (item && item.longitude && item.latitude)
    //   geocoder.reverse({lat: item.latitude, lng: item.longitude}, 8388608,
    //     results => {
    //       console.log({results})
    //       console.log(results[0].properties.address)
    //     });

    return (
      <div style={{padding: 10}}>
        {detailState === 'done' && item ? (
          item.latitude && item.longitude ? (
            <Map
              center={[item.latitude, item.longitude]}
              zoom={10}
              style={{width: `100%`, height: 240}}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />

              <Marker
                key={'park.properties.PARK_ID'}
                position={[item.latitude, item.longitude]}
                //   onClick={() => {
                //     setActivePark(park);
                //   }}
                //   icon={icon}
              />
            </Map>
          ) : (
            <span>Sorry, this user has no absolute item</span>
          )
        ) : (
          <Loader />
        )}
      </div>
    )
  })
)
