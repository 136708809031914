import React, {Component} from 'react'

export class TextAreaElement extends Component {
  render() {
    return (
      <textarea
        className="per-100"
        id={this.props.id}
        placeholder={this.props.placeholder}></textarea>
    )
  }
}
