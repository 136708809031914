import {inject, observer} from 'mobx-react'
import React from 'react'
import {
  FiUsers,
  MdLink,
  MdPayment,
  FaRegListAlt,
  RiPriceTag3Line,
  BsArrowRepeat,
} from 'react-icons/all'
import {Link, useParams} from 'react-router-dom'
import {DropDown} from '@evet/library'

export const Menu = inject('UIStore')(
  observer(props => {
    const {country = 'us'} = useParams()

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('Menu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('Menu', false)
      })
    }

    return (
      <React.Fragment>
        <DropDown label="Reporting">
          <Link to={`/${country}/extension`} onClick={toggleMenu}>
            <MdLink />
            <span>Extension Installs</span>
          </Link>
          <Link to={`/${country}/transactions`} onClick={toggleMenu}>
            <FiUsers />
            <span>Transaction</span>
          </Link>
          <Link to={`/${country}/browsing-history`} onClick={toggleMenu}>
            <MdPayment />
            <span>Website Browsing History</span>
          </Link>
          <Link to={`/${country}/promo-code`} onClick={toggleMenu}>
            <RiPriceTag3Line />
            <span>Promo Code</span>
          </Link>
          <Link to={`/${country}/conversions`} onClick={toggleMenu}>
            <BsArrowRepeat />
            <span>Conversions</span>
          </Link>
          <Link to={`/${country}/action-logs`} onClick={toggleMenu}>
            <FaRegListAlt />
            <span>Action Logs</span>
          </Link>
        </DropDown>
      </React.Fragment>
    )
  })
)
