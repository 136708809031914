import React, {Component} from 'react'

class NoMatch extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="h-100 m-h-auto">
          <div className="full-box">
            <h1>404 Not Found</h1>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default NoMatch
