import {DateTime} from 'components'
import {MoreLess, UserIdButton} from 'materials'
import React, {useState} from 'react'
import {ReferralModal} from './ReferralModal'

export const Row = props => {
  const {data: i} = props
  const [showReferral, setShowReferral] = useState(false)

  const handleReferral = () => setShowReferral(!showReferral)

  return (
    <tr key={'extensions-' + i.id}>
      <td nowrap={'nowrap'}>
        <DateTime
          timestamp={i.timestamp}
          native
          format={'MMM D YYYY | hh:mm A'}
        />
      </td>
      {/*<td nowrap={'nowrap'}>-</td>*/}
      <td nowrap={'nowrap'}>
        <UserIdButton id={i.id} />
      </td>
      <td>
        <MoreLess content={i.ip_address} maxLimit="16" />
      </td>
      <td>{i.city || i.province || '-'}</td>
      <td>{i.state || i.region || '-'}</td>
      <td>{i.country || '-'}</td>
      <td>
        {showReferral && (
          <ReferralModal
            onClose={handleReferral}
            id={i.id}
            data={i.install_referral_data}
          />
        )}
        {i.install_referral_data?.id ? (
          <div className="c-link text-underline" onClick={handleReferral}>
            <MoreLess
              content={i.install_referral_data.campaignMedium}
              maxLimit="18"
            />
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        {i.install_referral_data?.id ? (
          <div className="c-link text-underline " onClick={handleReferral}>
            <MoreLess
              content={i.install_referral_data.campaignTerm}
              maxLimit="18"
            />
          </div>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <MoreLess content={i.last_visited} maxLimit="30" />
      </td>
      <td className="vertical left middle p-0">
        <div className="removed-row">
          {i.installation_status === 'removed' && (
            <span className="message">{i.installation_status}</span>
          )}
        </div>
      </td>
    </tr>
  )
}
