export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('transaction meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Transaction {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  get id() {
    const id = this['record_id'] + '-' + this['index']
    return id
  }

  get timestamp() {
    return this['time']
  }
}
