import {
  DateTime,
  HeadLine,
  Loader,
  Pagination,
  TableLabel,
  WebsiteOpener,
} from 'components'
import {CustomDatePicker} from 'form'
import {qs, timeToUnix, useDefaultParams} from 'helpers'
import {QuickTips, ToggleMenu} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useState, useEffect} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {VisitModal} from './VisitModal'
import {HistoryServices} from 'services'

export const BrowsingHistory = inject(
  'HistoryStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore

    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const {id, country} = useParams()
    const [counter, setCounter] = useState(0)
    const [counterLoading, setCounterLoading] = useState(true)
    const [paramsAdded, setParamsAdded] = useState(false)
    const {page = 0, from, to, type} = params

    const startTime = timeToUnix(from)
    const endTime = timeToUnix(to)
    const init = paramsAdded && startTime && endTime && type

    useDefaultParams({
      once: {
        sort: '[{"field":"unique_visit","direction":"DESC"}]',
      },
      every: {
        type: 'generic',
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
      callback: setParamsAdded,
    })

    useDeepCompareEffect(() => {
      init &&
        props.HistoryStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.HistoryStore, page, params, init, startTime, endTime])
    const {state, meta, list} = props.HistoryStore

    useEffect(() => {
      if (init) {
        setCounterLoading(true)
        HistoryServices.readCounter({params: {type}}).then(res => {
          setCounter(res.count)
          setCounterLoading(false)
        })
      }
    }, [type, init])

    const handleTypeChange = value => {
      params.type = value
      historyPush()
    }

    const historyPush = () => {
      history.push('?' + qs.stringify(params))
    }

    const handleTimeChange = dates => {
      if (params.page) delete params['page']
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            ...params,
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Website Browsing History" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item">
              <p># of Websites :</p>
              {counterLoading ? (
                <div className="btn-loader"> </div>
              ) : (
                <span>{counter}</span>
              )}
            </div>
          </div>
        </HeadLine>

        {id && <VisitModal />}

        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <ToggleMenu
              size="small"
              options={[
                {label: 'Generic', value: 'generic'},
                {label: 'Checkout', value: 'checkout'},
                {label: 'Non-Affiliated', value: 'not_affiliate'},
                {label: 'Cart No Rule', value: 'cart_no_rule'},
                {label: 'No Coupon', value: 'no_coupon'},
                {label: 'No Merchant', value: 'no_merchant'},
              ]}
              onChange={handleTypeChange}
              defaultValue={params.type}
            />

            <div className="filter per-30 horizon right">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[new Date(from), new Date(to)]}
                />
              </div>
            </div>
          </div>
          {state === 'listing' ? (
            <Loader />
          ) : (
            <table className="list-table mt-20">
              <thead>
                <tr>
                  <td>
                    <TableLabel
                      label="DOMAIN"
                      field="domain"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <TableLabel
                      label="LAST VISITED"
                      field="last_visit"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <div className="horizon middle">
                      <TableLabel
                        label="UNIQUE VISIT"
                        field="unique_visit"
                        meta={meta}
                        store={props.HistoryStore}
                      />
                      <QuickTips comment="How many different user entered this website" />
                    </div>
                  </td>
                  <td>
                    <TableLabel
                      label="TOTAL VISIT"
                      field="total_visit"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                  <td>
                    <TableLabel
                      label="AVG VISIT"
                      field="average_visit_per_user"
                      meta={meta}
                      store={props.HistoryStore}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {/* average_visit: 15
              domain: "www.dickblick.com"
              last_visit: 1594650117881
              total_visit: 15
              unique_visit: 1 */}
                {list.map(i => (
                  <tr key={'extensions-' + i.id}>
                    <td nowrap={'nowrap'}>
                      <WebsiteOpener url={i.domain} limit={30} />
                    </td>

                    <td>
                      <DateTime
                        timestamp={i.last_visit}
                        format={'MMM D YYYY | hh:mm A'}
                      />
                    </td>

                    <td nowrap={'nowrap'}>
                      <Link
                        to={`/${country}/browsing-history/${
                          i.id
                        }?${qs.stringify(params)}`}>
                        {i.unique_visit}
                      </Link>
                    </td>

                    <td>
                      {/* <button className="btn basic" onClick={showVisited}> */}
                      {i.total_visit}
                      {/* </button> */}
                    </td>

                    <td nowrap={'nowrap'}>{i.average_visit_per_user}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {state !== 'listing' && (
            <Pagination
              className="pagination mt-30 mb-40"
              page={page || 0}
              count={meta.count}
              limit={100}
              range={9}
            />
          )}
        </div>
      </section>
    )
  })
)
