import {request} from 'helpers'

export class Services {
  static downloadFailed = async ({params = {}}) =>
    await request.download(
      `/download/failed-cp-report/`,
      params,
      true,
      'text/csv',
      'Failed Coupons'
    )

  static downloadSuccess = async ({params = {}}) =>
    await request.download(
      `/download/success-cp-report/`,
      params,
      true,
      'text/csv',
      'Success Coupons'
    )

  static update = async () => null

  static delete = async () => null

  //same as read
  static detail = async ({params = {}}) => {
    return await request.get(`/conversions/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/conversions/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/conversions/searchable`, {field, q})
}
