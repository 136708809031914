export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('promo meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class Promo {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  get id() {
    return `${this.merchant}_${this['code']}`
  }
}
