import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params = {}}) => {
    return await request.get(`/actions/list/`, params)
  }

  static update = async () => null

  static delete = async () => null

  //same as read
  static detail = async ({id = null, params = {}}) => {
    return await request.get(`/actions/list/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/actions/list/selectable`, {field})

  static searchable = async ({field, q}) =>
    await request.get(`/actions/list/searchable`, {field, q})
}
