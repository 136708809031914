import React from 'react'
import {FiEye} from 'react-icons/all'

export const Row = ({
  data: {
    id,
    action,
    action_list,
    count,
    domain,
    unique_user_count,
    user_details,
  },
  showActions = () => null,
  showUsers = () => null,
}) => {
  const handleActions = () => showActions(action_list, domain)
  const handleUsers = () => showUsers(user_details, domain)

  return (
    <tr>
      <td nowrap={'nowrap'}>{domain}</td>
      <td nowrap={'nowrap'}>{action}</td>
      <td nowrap={'nowrap'}>
        <button onClick={handleActions} className="btn basic p-0">
          {count || 0}
          {count && <FiEye className="ml-6" />}
        </button>
      </td>
      <td>
        <button onClick={handleUsers} className="btn basic p-0">
          {unique_user_count || 0}
          {unique_user_count && <FiEye className="ml-6" />}
        </button>
      </td>
    </tr>
  )
}
