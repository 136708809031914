import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params = null}) => await request.get(`/visits/`, params)

  static readCounter = async ({params = null}) =>
    await request.get('/visits/counter/', params)

  static update = async () => null

  static delete = async () => null

  //same as read
  static detail = async ({params}) => {
    const myParams = {...params}
    if (!myParams.userID) delete myParams['userID']
    if (!myParams.sessionID) delete myParams['sessionID']
    return await request.get(`/visits/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/visits/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/visits/searchable`, {field, q})
}
