import {ASide, UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'

export const UsageHistoryModal = inject(
  'UIStore',
  'PromoStore'
)(
  observer(props => {
    const {website, code, title, onClose} = props
    useEffect(() => {
      props.PromoStore.read({params: {website, code}})
    }, [props.PromoStore, website, code])

    const {item, detailState} = props.PromoStore
    return (
      <React.Fragment>
        <ASide onClose={onClose} title={title} loading={detailState !== 'done'}>
          <div className="usage-list p-10 modal-scroll-body">
            <table className="list-table-modal">
              <thead>
                <tr>
                  <td>TOTAL PRICE</td>
                  <td>SAVING AMOUNT</td>
                  <td>FINAL PRICE</td>
                  <td>User Id</td>
                </tr>
              </thead>
              <tbody>
                {item.map((i, index) => (
                  <tr key={'promo-modal-' + i.id + index}>
                    <td>{i.ex_price}</td>
                    <td>{i.discount}</td>
                    <td>{i.new_price}</td>
                    <td>
                      <UserIdButton id={i.user_id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ASide>
      </React.Fragment>
    )
  })
)
