import {computed, makeObservable, observable} from 'mobx'

export class Profile {
  publishers = null
  publisher = {id: 0, properties: [{id: 0, name: '-'}]}
  property = {id: 0, name: '-'}

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
    if (this.publishers && this.publishers.length)
      this.publisher = this.publishers[0]
    if (
      this.publisher &&
      this.publisher.properties &&
      this.publisher.properties.length
    )
      this.property = this.publisher.properties[0]
    makeObservable(this, {
      publishers: observable,
      publisher: observable,
      property: observable,
      publisher_id: computed,
      property_id: computed,
    })
  }

  get publisher_id() {
    return this.publisher.id
  }

  get property_id() {
    return this.property.id
  }

  changePublisher(id) {
    this.publisher = this.publishers.find(i => i.id === id)
    if (
      this.publisher &&
      this.publisher.properties &&
      this.publisher.properties.length
    )
      this.property = this.publisher.properties[0]
  }
}
