export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('history meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class History {
  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }

  get id() {
    return this['record_id'] || this['domain']
    // return this['time']
  }

  get user_id() {
    return this['userID']
  }

  get session_id() {
    return this['sessionID'] || ''
  }

  get current_url() {
    return this['currentURL']
  }

  get timestamp() {
    return this['time']
  }
}
