import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params = {}}) => {
    const myParams = {...params, isSuccessful: true}
    if (!myParams.isSuccessful) myParams.isSuccessful = true

    return await request.get(`/promo-codes/`, myParams)
  }

  static update = async () => null

  static delete = async () => null

  static detail = async ({params}) => {
    return await request.post(`/coupon-details/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/promo-codes/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/promo-codes/searchable`, {field, q})
}
