import {MoreLess} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {FaUser} from 'react-icons/all'
import {UserProfile} from '../'

export const UserIdButton = inject('UIStore')(
  observer(props => {
    const [showModal, setShowModal] = useState(false)
    const handleShowModal = () => setShowModal(!showModal)

    return (
      <React.Fragment>
        {showModal && <UserProfile id={props.id} onClose={handleShowModal} />}
        <button
          onClick={handleShowModal}
          className={
            'user-id-button horizon between middle ' + props.className
          }>
          <div className="icon">{props.icon ? props.icon : <FaUser />}</div>
          <p>
            <MoreLess
              content={props.id}
              maxLimit={props.id?.split('-')[0].length}
            />
          </p>
        </button>
      </React.Fragment>
    )
  })
)
