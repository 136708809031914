import {ASide, MoreLess, UserIdButton} from 'materials'
import React from 'react'

export const ActionLists = ({list = [], title, onClose = () => null}) => (
  <ASide title={title} className="per-50" onClose={onClose}>
    <div id="scrollBar" className="scrollable-base">
      <table className="list-table">
        <thead>
          <tr>
            <td>User ID</td>
            <td>Current URL</td>
            <td>Action Type</td>
            <td>Targeted URL</td>
            <td>Copied Code</td>
          </tr>
        </thead>
        <tbody>
          {list.map(
            ({
              action_type,
              copied_code, // current_domain,
              current_url,
              session_id,
              targeted_url,
              user_id,
            }) => (
              <tr key={'action-list-' + user_id + session_id + Math.random()}>
                <td>
                  <UserIdButton id={user_id} />
                </td>
                <td>
                  <MoreLess maxLimit="20" content={current_url} />
                </td>
                <td>{action_type}</td>
                <td>
                  <MoreLess maxLimit="20" content={targeted_url} />
                </td>
                <td>{copied_code}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  </ASide>
)
