import {WebsiteOpener} from 'components'
import {inject, observer} from 'mobx-react'
import {qs} from 'helpers'
import React, {useEffect} from 'react'
import {
  AiOutlineArrowRight,
  CgPlayListCheck,
  FaRegTimesCircle,
} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'

const Item = props => {
  const {name, onClick, className, noClick} = props

  const handleClick = () => {
    if (!noClick) onClick(name)
  }
  return (
    <div onClick={handleClick} className={className}>
      {props.children}
    </div>
  )
}

export const SummaryData = inject('MetaStatsStore')(
  observer(props => {
    const {meta, from, to} = props
    const {couponTransactions, websiteTransactions} = props.MetaStatsStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    useEffect(() => {
      props.MetaStatsStore.readCouponTransactions({params: {from, to}})
      props.MetaStatsStore.readWebsiteTransactions({params: {from, to}})
    }, [props.MetaStatsStore, from, to])

    const handleClick = name => {
      params.search = `[{"field":"is_discounted","q":["${name}"],"include":true}]`
      history.push('?' + qs.stringify(params))
    }

    const checkActive = name => {
      if (
        params.search ===
        `[{"field":"is_discounted","q":["${name}"],"include":true}]`
      )
        return 'card item per-20 active'
      else return 'card item per-20'
    }

    return (
      <div
        className={
          'summary-card-base horizon between per-100 middle ' + props.className
        }>
        <Item
          className={checkActive('True')}
          name={'True'}
          onClick={handleClick}>
          <span>Successfully</span>
          <p>
            <CgPlayListCheck />
            {meta.discounted_count || 0}
          </p>
          <button className="btn basic">View List</button>
        </Item>
        <Item
          className={checkActive('False')}
          name={'False'}
          onClick={handleClick}>
          <span>Unsuccessfully</span>
          <p>
            <FaRegTimesCircle /> {meta.not_discounted_count || 0}
          </p>
          <button className="btn basic">View List</button>
        </Item>

        <div className="card  per-58 tab-menu p-0">
          <Tabs className="horizon left between per-100 h-100">
            <TabList className="vertical h-100 per-30 border-box">
              <Tab style={{flex: 1}}>
                Top 3 Coupon Transactions
                <AiOutlineArrowRight />
              </Tab>
              <Tab style={{flex: 1}}>
                Top 3 Website Transactions
                <AiOutlineArrowRight />
              </Tab>
            </TabList>
            <div className="per-70 h-100 vertical top">
              <TabPanel className="per-100 border-box">
                <table className="list-table">
                  <thead>
                    <tr>
                      <td>Merchant URL</td>
                      <td>Coupon Code</td>
                      <td>Total PRICE</td>
                      <td>Saving Amount</td>
                      <td>Final Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    {couponTransactions.map(i => (
                      <tr key={'summarycoupon' + i.id}>
                        <td>
                          <WebsiteOpener url={i.website} />
                        </td>
                        <td>{i.code}</td>
                        <td>{i.total_old_price}</td>
                        <td>{i.total_discount}</td>
                        <td>{i.total_new_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TabPanel>
              <TabPanel className="per-100 border-box">
                <table className="list-table">
                  <thead>
                    <tr>
                      <td>Merchant URL</td>
                      {/*<td>Coupon Code</td>*/}
                      <td>Total PRICE</td>
                      <td>Saving Amount</td>
                      <td>Final Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    {websiteTransactions.map(i => (
                      <tr key={'summarycoupon' + i.id}>
                        <td>
                          <WebsiteOpener url={i.website} />
                        </td>
                        {/*<td>{i.code}</td>*/}
                        <td>{i.total_old_price}</td>
                        <td>{i.total_discount}</td>
                        <td>{i.total_new_price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TabPanel>
              {/*<TabPanel className="per-100 border-box">*/}
              {/*  <table className="list-table">*/}
              {/*    <thead>*/}
              {/*      <tr>*/}
              {/*        <td>Merchant URL</td>*/}
              {/*        <td>Coupon Code</td>*/}
              {/*        <td>Total PRICE</td>*/}
              {/*        <td>Saving Amount</td>*/}
              {/*        <td>Final Price</td>*/}
              {/*      </tr>*/}
              {/*    </thead>*/}
              {/*    <tbody>*/}
              {/*      <tr>*/}
              {/*        <td>*/}
              {/*          <Link to="">*/}
              {/*            <MoreLess content="www.blabla.com" maxLimit="10" />*/}
              {/*          </Link>*/}
              {/*        </td>*/}
              {/*        <td>FREECOUPON</td>*/}
              {/*        <td>$123</td>*/}
              {/*        <td>$23</td>*/}
              {/*        <td>$100</td>*/}
              {/*      </tr>*/}
              {/*      <tr>*/}
              {/*        <td>*/}
              {/*          <Link to="">*/}
              {/*            <MoreLess content="www.blabla.com" maxLimit="10" />*/}
              {/*          </Link>*/}
              {/*        </td>*/}
              {/*        <td>54278</td>*/}
              {/*        <td>$123</td>*/}
              {/*        <td>$23</td>*/}
              {/*        <td>$100</td>*/}
              {/*      </tr>*/}
              {/*      <tr>*/}
              {/*        <td>*/}
              {/*          <Link to="">*/}
              {/*            <MoreLess content="www.blabla.com" maxLimit="10" />*/}
              {/*          </Link>*/}
              {/*        </td>*/}
              {/*        <td>Unlimited</td>*/}
              {/*        <td>$123</td>*/}
              {/*        <td>$23</td>*/}
              {/*        <td>$100</td>*/}
              {/*      </tr>*/}
              {/*    </tbody>*/}
              {/*  </table>*/}
              {/*</TabPanel>*/}
            </div>
          </Tabs>
        </div>
      </div>
    )
  })
)
