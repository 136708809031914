import React from 'react'
import {Link} from 'react-router-dom'

export const Item = props => {
  return (
    <Link
      to={props.to}
      className={'item horizon left top' + (props.read ? ' read' : '')}>
      <div className="icon ">{props.icon}</div>
      <div className="content vertical pl-10">
        <span className="sz-14 fw-500 f-grey">{props.title}</span>
        <span className="sz-12 fw-300 f-grey">{props.date}</span>
      </div>
    </Link>
  )
}
