import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Promo as Model, PromoMeta as Meta} from '../models'
import {PromoServices as Service} from '../services'

export default class Store {
  limit = 25
  _list = new observable.map()
  meta = {}

  state = 'initial'
  _item = new observable.map()

  detailState = 'initial'
  _searchable = {}

  _selectable = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      meta: observable,
      state: observable,

      _item: observable,
      detailState: observable,

      _searchable: observable,
      _selectable: observable,

      list: computed,
      item: computed,

      read: action,
      searchable: action,
      selectable: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return [...this._item.values()]
  }

  read({params}) {
    if (params.website && params.code) {
      this.detailState = 'fetching'
      Service.detail({params}).then(res => {
        this.detailState = 'done'
        this._item = new observable.map()
        res.forEach(i => {
          const item = new Model(i || {})
          this._item.set(item.record_id, item)
        })
      }, this.handleError)
    } else {
      const limit = this.limit
      const offset = params.page * limit
      this.state = 'fetching'
      Service.read({
        params: {...params, limit, offset},
      }).then(this.fetchSuccess, this.handleError)
    }
  }

  fetchSuccess = res => {
    if (res.meta) {
      this.meta = new Meta(res.meta)
    }
    if (res.data) {
      this._list = new observable.map()
      res.data.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  async selectable({field}) {
    return (
      toJS(this._selectable[field]) ||
      (this._selectable[field] = (await Service.selectable({field}))['data'][
        field
      ])
    )
  }

  async searchable({field, q}) {
    return (
      toJS(this._searchable[field + '-' + q]) ||
      (this._searchable[field + '-' + q] = (
        await Service.searchable({
          field,
          q,
        })
      )['data'][field])
    )
  }
}
