import {DateTime} from 'components'
import {ASide, UserIdButton} from 'materials'
import React from 'react'

export const UserLists = ({list = [], title, onClose = () => null}) => (
  <ASide title={title} className="per-50" onClose={onClose}>
    <div id="scrollBar" className="scrollable-base">
      <table className="list-table">
        <thead>
          <tr>
            <td>User ID</td>
            <td>IP Address</td>
            <td>Install Date</td>
            <td>Device Type</td>
            <td>Location</td>
          </tr>
        </thead>
        <tbody>
          {list.map((
            {
              device_type, // installation_status, // honey_installed,
              ip_address,
              location_data = {},
              timestamp,
              user_id,
            } // last_visited, // rtm_installed,
          ) => (
            <tr key={'users-list-' + user_id}>
              <td>
                <UserIdButton id={user_id} />
              </td>
              <td>{ip_address}</td>
              <td>
                <DateTime
                  timestamp={timestamp}
                  format={'MMM D YYYY | hh:mm A'}
                />
              </td>
              <td>{device_type}</td>
              <td>{`${location_data.city ? location_data.city + ' / ' : ''}${
                location_data.country
              }`}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </ASide>
)
