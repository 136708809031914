import {inject, observer} from 'mobx-react'
// import {ArrayEditor, SchemaTypes} from 'object-editor-react';
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import ReactJson from 'react-json-view'
import {useParams} from 'react-router-dom'
import {WebsiteOpener} from 'components'
import {ASide, InputElement, PrimaryButton, SelectBox} from 'materials'

export const Add = inject('RuleStore')(
  observer(props => {
    const {register, handleSubmit, errors} = useForm()
    const {id} = useParams()
    const {item} = props.RuleStore
    const {loading} = props

    const [jsonData, setJsonData] = useState({})

    const handleCloseModal = () => {
      props.onClose()
    }

    useEffect(() => {
      if (id)
        props.RuleStore.read({id}).then(res => {
          setJsonData(res.rule_pairs)
        })

      // old style json editor
      // Object.entries(res.rule_pairs).map(([key, val]) => ({
      // 	route: key,
      // 	rule: val.rule,
      // 	rule_count: val.rule_count,
      // 	rule_type: val.rule_type,
      // }))
    }, [id, props.RuleStore])

    // const handleAdd = newElement => {
    // 	setJsonData([...jsonData, newElement]);
    // };
    //
    // const handleDelete = removedIndices => {
    // 	setJsonData(
    // 		jsonData.filter(function (value, index) {
    // 			return removedIndices.indexOf(index) === -1;
    // 		})
    // 	);
    // };
    //
    // const handleUpdate = (updatedElement, updatedIndex) => {
    // 	const newData = [...jsonData];
    // 	newData[updatedIndex] = updatedElement;
    // 	setJsonData(newData);
    // };

    const onSubmit = async data => {
      // return console.log({data})
      // const rule_pairs = {};
      //
      // jsonData.forEach(i => rule_pairs[i.route] = {
      // 	rule: i.rule,
      // 	rule_count: i.rule_count,
      // 	rule_type: i.rule_type
      // 	});

      await props.RuleStore.addOrUpdate({
        id,
        data: {...data, rule_pairs: jsonData},
      })

      props.onClose()
    }

    const onAdd = data => {
      // console.log({data})
      setJsonData(data.updated_src)
    }

    const onEdit = data => {
      // console.log({data})
      setJsonData(data.updated_src)
    }

    const onDelete = data => {
      // console.log({data})
      setJsonData(data.updated_src)
    }

    return (
      <ASide
        onClose={handleCloseModal}
        title={id ? (item.domain || '') + ' - Rule Edit' : 'Rule Create'}
        loading={loading}
        className="large-size">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="vertical  top per-100">
          <div className="horizon between per-100 top">
            <div className="vertical top left per-30 p-20">
              <label className="vertical per-100 left ">
                <span>Domain :</span>
                <InputElement
                  name="domain"
                  className="per-100"
                  errors={errors}
                  defaultValue={id ? item.domain : ''}
                  disabled={id}
                  messages={{
                    required: 'This field is required!',
                  }}
                  ref={register({
                    required: true,
                  })}
                />
              </label>
              <label className="vertical per-100 left mt-20">
                <span>Type :</span>
                <SelectBox
                  name="type"
                  className="per-100"
                  errors={errors}
                  defaultValue={id ? item.type : ''}
                  messages={{
                    required: 'This field is required!',
                  }}
                  ref={register({
                    required: true,
                  })}>
                  <option value="" id="none">
                    Type
                  </option>
                  <option value="custom" id="custom">
                    Custom
                  </option>
                  <option value="magento" id="magento">
                    Magento
                  </option>
                  <option value="shopify" id="shopify">
                    Shopify
                  </option>
                </SelectBox>
              </label>
              <div className="mt-20">
                <p className="sz-12">Keyboard Shortcuts</p>
                <ul className="pl-20 m-0 sz-12 fw-300">
                  <li>To edit a value, try ctrl + click enter edit mode</li>
                  <li>
                    When editing a value, try ctrl + Enter to submit changes
                  </li>
                  <li>When editing a value, try Escape key to cancel</li>
                  <li>When adding a new key, try Enter to submit</li>
                  <li>When adding a new key, try Escape to cancel</li>
                  <li>
                    For change data types, you can write "&#123; &#125;", "[ ]",
                    number etc. as a value and apply type change
                  </li>
                  <li>For More Information:</li>
                  <li>
                    <WebsiteOpener
                      url={'https://www.npmjs.com/package/react-json-view'}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className=" vertical top between per-69 p-20 ">
              {/*<JSONEditor*/}
              {/*	data={{rule_pairs : item.rule_pairs}}*/}
              {/*	collapsible*/}
              {/*	onChange={handleJsonEdit}*/}
              {/*	view='dual'*/}
              {/*	styles={styles} // for styling, we define 'styles' variable below*/}
              {/*/>*/}
              {/*<div className="per-70">*/}
              {/*<ArrayEditor*/}
              {/*	defaultRowsPerPage={10}*/}
              {/*	type={schema}*/}
              {/*	object={jsonData}*/}
              {/*	onUpdateElement={handleUpdate}*/}
              {/*	onAddElement={handleAdd}*/}
              {/*	onRemoveElements={handleDelete}*/}
              {/*	parentVisible={false}*/}
              {/*/>*/}
              <div className="horizon left middle">
                <p className="sz-12">Rules Editor</p>
              </div>
              <div id="scrollBar" className="json-editor">
                <ReactJson
                  collapsed={false}
                  name="rule_pairs"
                  src={jsonData}
                  defaultValue=""
                  onAdd={onAdd}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  displayDataTypes
                  theme="monokai"
                  style={{padding: 20}}
                  enableClipboard
                  validationMessage="Validation Error"
                />
              </div>
            </div>
          </div>

          {/*	<div className="per-30">*/}
          {/*		<JSONViewer data={jsonData} collapsible styles={styles}/>*/}
          {/*	</div>*/}
          {/*</div>*/}
          <div className="vertical per-100 middle right border-box pr-20">
            <PrimaryButton type="submit" className="horizon center mb-10">
              Save
            </PrimaryButton>
          </div>
        </form>
      </ASide>
    )
  })
)

// const styles = {
// 	dualView: {
// 		display: 'flex',
// 		backgroundColor: 'black',
// 	},
// 	jsonViewer: {
// 		borderLeft: '1px dashed white',
// 		width: '40%',
// 		margin: 5,
// 		lineHeight: 1.25,
// 	},
// 	jsonEditor: {
// 		width: '60%',
// 		fontSize: 16,
// 		fontFamily: 'Lucida Console, monospace',
// 		margin: 5,
// 		lineHeight: 1.25,
// 	},
// 	root: {
// 		fontSize: 16,
// 		margin: 5,
// 		fontFamily: 'Lucida Console, monospace',
// 		backgroundColor: 'black',
// 		lineHeight: 1.25,
// 		/*color: "#3E3D32"*/
// 	},
// 	label: {
// 		color: 'DeepPink',
// 		marginTop: 3,
// 	},
// 	value: {
// 		marginLeft: 10,
// 	},
// 	row: {
// 		display: 'flex',
// 	},
// 	withChildrenLabel: {
// 		color: 'DeepPink',
// 	},
// 	select: {
// 		borderRadius: 3,
// 		borderColor: 'grey',
// 		backgroundColor: 'DimGray',
// 		color: 'khaki',
// 	},
// 	input: {
// 		borderRadius: 3,
// 		border: '1px solid #272822',
// 		padding: 2,
// 		fontFamily: 'Lucida Console, monospace',
// 		fontSize: 16,
// 		backgroundColor: 'DimGray',
// 		color: 'khaki',
// 		width: '200%',
// 	},
// 	addButton: {
// 		cursor: 'pointer',
// 		color: 'LightGreen',
// 		position: 'relative',
// 		marginLeft: 15,
// 		fontSize: 16,
// 		right: 10,
// 	},
// 	removeButton: {
// 		cursor: 'pointer',
// 		color: 'magenta',
// 		position: 'relative',
// 		marginLeft: 30,
// 		fontSize: 20,
// 		right: 30,
// 	},
// 	saveButton: {
// 		cursor: 'pointer',
// 		color: 'green',
// 		position: 'relative',
// 		marginLeft: 30,
// 		fontSize: 20,
// 		right: 20,
// 	},
// 	builtin: {
// 		color: 'green',
// 		fontSize: 16,
// 	},
// 	text: {
// 		color: 'khaki',
// 		fontSize: 16,
// 	},
// 	number: {
// 		color: 'purple',
// 		fontSize: 16,
// 	},
// 	property: {
// 		color: 'DeepPink',
// 		fontSize: 16,
// 	},
// 	collapseIcon: {
// 		cursor: 'pointer',
// 		fontSize: 14,
// 		color: 'teal',
// 	},
// };

// const schema = {
// 	route: SchemaTypes.string(),
//
// 	rule: SchemaTypes.string(),
// 	rule_count: SchemaTypes.string(),
// 	rule_type: SchemaTypes.string(),
// };
