import React from 'react'

export const PrimaryButton = props => {
  const loading = props.loading || false
  return (
    <button
      onClick={props.onClick || null}
      type={props.type || 'button'}
      className={
        'btn primary ' + props.className + (loading ? ' submit-loader' : '')
      }>
      {props.children}
      {props.name}
    </button>
  )
}
