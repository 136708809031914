import React, {useState} from 'react'

export const MoreLess = ({content = '', maxLimit = 12, openBox = ''}) => {
  const [moreContent, setMoreContent] = useState(false)

  const handleMouseHover = () => {
    setMoreContent(!moreContent)
  }

  return (
    <span
      className="more-less"
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}>
      {moreContent && content && content.length > maxLimit ? (
        <span className={'full-content shadow-box ' + openBox}>{content}</span>
      ) : null}
      {content &&
        (content.length > maxLimit
          ? content.slice(0, maxLimit) + '...'
          : content)}
    </span>
  )
}
