import {ASide, UserIdButton} from 'materials'
import React from 'react'

export const ReferralModal = props => {
  const {id, data, onClose} = props

  const {
    campaignContent,
    campaignMedium,
    campaignName,
    campaignSource,
    campaignTerm,
    id: refID,
    referralDate,
  } = data

  return (
    <ASide
      title={
        <div className="horizon center middle">
          {' '}
          <UserIdButton id={id} />
          <span className="pl-10"> - Referral Modal</span>
        </div>
      }
      onClose={onClose}>
      <table className="extension-referral-modal">
        <thead>
          <tr>
            <td className="label per-50">Campaign Name</td>
            <td className="value per-50">{': ' + campaignName}</td>
          </tr>
          <tr>
            <td className="label per-50">Campaign Source</td>
            <td className="value per-50">{': ' + campaignSource}</td>
          </tr>
          <tr>
            <td className="label per-50">Campaign Content</td>
            <td className="value per-50">{': ' + campaignContent}</td>
          </tr>
          <tr>
            <td className="label per-50">Campaign Medium</td>
            <td className="value per-50">{': ' + campaignMedium}</td>
          </tr>
          <tr>
            <td className="label per-50">Campaign Term</td>
            <td className="value per-50">{': ' + campaignTerm}</td>
          </tr>
          <tr>
            <td className="label per-50">ID</td>
            <td className="value per-50">{': ' + refID}</td>
          </tr>
          <tr>
            <td className="label per-50">Referral Date</td>
            <td className="value per-50">{': ' + referralDate}</td>
          </tr>
        </thead>
      </table>
    </ASide>
  )
}
