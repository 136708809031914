import {HeadLine, Loader, TableLabel} from 'components'
import {CustomDatePicker} from 'form'
import {qs, timeToUnix, useDefaultParams} from 'helpers'
import {inject, observer} from 'mobx-react'
import {ActionLists} from 'pages/ActionLog/ActionsList'
import {UserLists} from 'pages/ActionLog/UserList'
import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Row} from './Row'

export const ActionLogs = inject(
  'ProfileStore',
  'ActionLogStore',
  'UIStore'
)(
  observer(props => {
    const {today, daysAgo} = props.ProfileStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [paramsAdded, setParamsAdded] = useState(false)
    const {page = 0, from, to} = params

    const startTime = timeToUnix(from)
    const endTime = timeToUnix(to)

    const init = paramsAdded && startTime && endTime

    const [actionList, setActionList] = useState([])
    const [userList, setUserList] = useState([])
    const [domain, setDomain] = useState('')

    const showActions = (items, domain) => {
      setDomain(domain)
      setActionList(items || [])
    }
    const showUsers = (items, domain) => {
      setDomain(domain)
      setUserList(items || [])
    }

    useDefaultParams({
      callback: setParamsAdded,
      once: {
        sort: '[{"field":"timestamp","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || daysAgo(7).startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    useDeepCompareEffect(() => {
      init &&
        props.ActionLogStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.ActionLogStore, page, params, startTime, endTime, init])
    const {state, meta, list} = props.ActionLogStore

    const handleTimeChange = dates => {
      if (params.page) delete params['page']
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            ...params,
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Action Log" className="sz-30 fw-300" />
        {actionList.length ? (
          <ActionLists list={actionList} title={domain} onClose={showActions} />
        ) : null}
        {userList.length ? (
          <UserLists list={userList} title={domain} onClose={showUsers} />
        ) : null}
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div>
              {/*<SelectBox*/}
              {/*	label="Sort By"*/}
              {/*	onChange={handleSort}*/}
              {/*	defaultValue={sortBy}*/}
              {/*>*/}
              {/*	<option value='time-desc'>Time-desc</option>*/}
              {/*	<option value='time-asc'>Time-asc</option>*/}
              {/*</SelectBox>*/}
            </div>
            <div className="filter per-50 horizon right">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[new Date(from), new Date(to)]}
                />
              </div>
            </div>
          </div>

          {state !== 'listing' ? (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      <TableLabel
                        label="UNIQ DOMAIN"
                        field="current_domain"
                        meta={meta}
                        store={props.ActionLogStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="ACTION TYPE"
                        field="action_type"
                        meta={meta}
                        store={props.ActionLogStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="ACTION COUNT"
                        field="count"
                        meta={meta}
                        store={props.ActionLogStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="USER COUNT"
                        field="unique_user_count"
                        meta={meta}
                        store={props.ActionLogStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {state === 'done' &&
                    list.map(i => (
                      <Row
                        key={'action-log-' + i.id}
                        data={i}
                        showActions={showActions}
                        showUsers={showUsers}
                      />
                    ))}
                </tbody>
              </table>
              {/*<Pagination*/}
              {/*  className="pagination mt-30 mb-40"*/}
              {/*  page={page || 0}*/}
              {/*  count={meta.total_count}*/}
              {/*  limit={100}*/}
              {/*  range={9}*/}
              {/*/>*/}
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </section>
    )
  })
)
