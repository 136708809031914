import {withStyles} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import React, {useState} from 'react'

const StyleToggleMenu = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  sizeSmall: {
    padding: '4px 20px',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
  sizeLarge: {
    padding: '12px 20px',
    fontSize: '12px',
    textTransform: 'capitalize',
  },
}))(ToggleButton)

export const ToggleMenu = props => {
  const [alignment, setAlignment] = useState(props.defaultValue)

  const handleAlignment = (e, value) => {
    setAlignment(value)
    if (props.onChange) props.onChange(value)
  }

  return (
    <ToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment">
      {(props.options || []).map(i => (
        <StyleToggleMenu
          size={props.size}
          key={'option-' + i.label}
          value={i.value}>
          {i.label}
        </StyleToggleMenu>
      ))}
    </ToggleButtonGroup>
  )
}
