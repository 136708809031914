import {InputBase, makeStyles, withStyles} from '@material-ui/core'
import * as get from 'lodash.get' // lodash get required for nested objects
import React from 'react'
import {useFormContext} from 'react-hook-form'
import {BsSearch} from 'react-icons/all'

const StyledInput = withStyles(theme => ({
  root: {
    backgroundColor: '#f9f9f9',
    borderRadius: 6,
    svg: {
      color: 'red',
    },
  },
  input: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: '#f9f9f9',
    border: '1px solid #e3e3e3',
    fontSize: 12,
    width: '100%',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#1bb73f',
    },
    '&:disabled': {
      color: '#636363',
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  searchIcon: {
    position: 'absolute',
    right: '10px',
    zIndex: '40',
    color: '#666',
    fontSize: '12px',
  },
  label: {
    fontSize: '12px',
    color: '#777777',
    marginBottom: '6px',
  },
}))

export const CustomInput = props => {
  const {register, errors} = useFormContext()
  const {className, label, name, placeholder, icon, rows, ...rest} = props
  const error = get(errors, name)
  const classes = useStyles()

  return (
    <React.Fragment>
      <label
        style={{position: 'relative'}}
        className={'vertical left middle ' + className}>
        {label && <span className={classes.label}>{label}</span>}
        {icon === 'search' && <BsSearch className={classes.searchIcon} />}
        <StyledInput
          {...rest}
          name={name}
          className="per-100 "
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          inputRef={name ? register : null}
          icon={icon}
          multiline={rows}
          rows={rows}
        />
        {error && <p className="c-alert pt-6 sz-12">{error.message}</p>}
      </label>
    </React.Fragment>
  )
}
