import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {Link} from 'react-router-dom'

export const SignOut = inject('AuthStore')(
  observer(props => {
    useEffect(() => {
      props.AuthStore.signOut()
    }, [props.AuthStore])

    return (
      <section className="h-100 m-h-auto">
        <div className="full-box p-20">
          <div className="text-center p-20">
            Sorry to see you go ...{' '}
            <Link to={process.env.REACT_APP_AUTH}>back to login</Link>
          </div>
        </div>
      </section>
    )
  })
)
