import InputBase from '@material-ui/core/InputBase'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const StyledInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {},
}))(InputBase)

export const CustomCheckBox = props => {
  const {register, errors} = useFormContext()
  const {className, label, name, ...rest} = props

  return (
    <React.Fragment>
      <label className={className + ' horizon left middle'}>
        <StyledInput
          {...rest}
          name={name}
          variant="outlined"
          className="per-10"
          inputRef={register}
          inputProps={{type: 'checkbox'}}
        />
        <p className="ml-10 sz-14 f-400">{label}</p>
      </label>
      {errors[name] && (
        <div className="error per-100">
          <span>{errors[name].message}</span>
        </div>
      )}
    </React.Fragment>
  )
}
