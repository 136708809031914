import {request as Request} from '@evet/library'

import {endpoints} from 'helpers/endpoints'
const country = localStorage.getItem('country') || 'us'
const API_URL = endpoints[country] //process.env.REACT_APP_API_URL;

export const request = new Request({
  api_url: API_URL,
  cookie_domain: process.env.REACT_APP_COOKIE_DOMAIN,
  refresh_token_url: process.env.REACT_APP_REFRESH_TOKEN,
})

// import cookie from 'react-cookies'
// import {qs} from 'helpers'
// import jwt_decode from 'jwt-decode'
// import FileSaver from 'file-saver'
// import {CustomAPIError} from './CustomAPIError'
// import {endpoints} from 'helpers/endpoints'
//
// const country = localStorage.getItem('country') || 'us'
// const API_URL = endpoints[country] //process.env.REACT_APP_API_URL;
// const domain = process.env.REACT_APP_COOKIE_DOMAIN
//
// export class request {
//   static get token() {
//     const token = cookie.load('access')
//     return token || this.refreshToken().then()
//   }
//
//   static get access() {
//     return cookie.load('access')
//   }
//
//   static refreshToken = async () => {
//     const {refresh: token, remember} = cookie.loadAll()
//     if (token) {
//       return await fetch(process.env.REACT_APP_REFRESH_TOKEN, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         mode: 'cors',
//         body: JSON.stringify({token}),
//       })
//         .then(async res => await res.json())
//         .then(res => {
//           this.setCookies({...res, remember})
//           return res.access
//         })
//         .catch(error => {
//           this.deleteCookies()
//           console.log({error})
//         })
//     } else {
//       console.log('There is no refresh token!')
//       this.deleteCookies()
//       return false
//     }
//   }
//
//   static deleteCookies = () => {
//     localStorage.removeItem('location')
//     localStorage.removeItem('me')
//     // localStorage.clear();
//     cookie.save('access', '', {path: '/', domain})
//     cookie.save('refresh', '', {path: '/', domain})
//     cookie.save('remember', '', {path: '/', domain})
//     cookie.remove('access', {path: '/', domain})
//     cookie.remove('refresh', {path: '/', domain})
//     cookie.remove('remember', {path: '/', domain})
//     cookie.remove('access', {path: '/'})
//     cookie.remove('refresh', {path: '/'})
//     cookie.remove('remember', {path: '/'})
//   }
//
//   static setCookies = ({access, refresh = null, remember = false}) => {
//     const {max_age: access_exp} = jwt_decode(access)
//     if (access)
//       cookie.save('access', access, {
//         path: '/',
//         maxAge: access_exp,
//         domain,
//       })
//     if (refresh) {
//       const {max_age: refresh_exp} = jwt_decode(refresh)
//       cookie.save('refresh', refresh, {
//         path: '/',
//         maxAge: refresh_exp,
//         domain,
//       })
//       cookie.save('remember', remember, {
//         path: '/',
//         maxAge: refresh_exp,
//         domain,
//       })
//     }
//   }
//
//   static get = async (endpoint, params = {}, secured = true) => {
//     // if (params)
//     //   Object.entries(params).forEach(([key, val]) => {
//     //     if (typeof val === 'string' && val.indexOf(',') !== -1)
//     //       params[key] = val.split(',')
//     //   })
//     params = params ? qs.stringify(params) : ''
//     endpoint += params ? '?' + params : ''
//     return await request.fetcher('GET', endpoint, null, secured)
//   }
//
//   static post = async (endpoint, data, secured = true) => {
//     return await request.fetcher(
//       'POST',
//       endpoint,
//       JSON.stringify(data),
//       secured
//     )
//   }
//
//   static patch = async (endpoint, data, secured = true) => {
//     return await request.fetcher(
//       'PATCH',
//       endpoint,
//       JSON.stringify(data),
//       secured
//     )
//   }
//
//   static put = async (endpoint, data, secured = true) => {
//     return await request.fetcher('PUT', endpoint, JSON.stringify(data), secured)
//   }
//
//   static delete = async (endpoint, data, secured = true) => {
//     return await request.fetcher(
//       'DELETE',
//       endpoint,
//       JSON.stringify(data),
//       secured
//     )
//   }
//
//   static fetcher = async (method, url, body = null, secured = null) => {
//     const token = secured ? await request.token : null
//     if (secured && !token) return false
//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     if (token) headers.Authorization = 'Bearer ' + token
//     const response = await fetch(API_URL + url, {
//       method: method || 'GET',
//       headers,
//       mode: 'cors',
//       body,
//     })
//     const result = await response.json()
//     if (response.status <= 201) return result || true
//     throw new CustomAPIError({code: response.status, ...result})
//   }
//
//   static download = async (
//     endpoint,
//     params = {},
//     secured = true,
//     contentType = 'text/csv',
//     fileName = 'report'
//   ) => {
//     const token = secured ? await this.token : null
//     if (secured && !token) return false
//     params = params ? qs.stringify(params) : ''
//     const url = API_URL + endpoint + (params ? '?' + params : '')
//
//     const response = await fetch(url, {
//       method: 'GET',
//       headers: {
//         'Content-Type': contentType,
//         Authorization: token,
//       },
//       mode: 'cors',
//     })
//
//     const result = await response.json()
//
//     await fetch(result.data, {
//       method: 'GET',
//       headers: {
//         'Content-Type': contentType,
//         // no auth header
//       },
//       mode: 'cors',
//       responseType: 'blob',
//     })
//       .then(response => response.blob())
//       .then(blob => FileSaver.saveAs(blob, fileName + '.csv'))
//   }
// }
