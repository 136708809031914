import {request} from 'helpers'

export class Services {
  static create = async ({data}) => {
    return await request.post(`/management/rules/create/`, data)
  }

  static update = async ({id, data}) => {
    return await request.post(`/management/rules/${id}/update`, data)
  }

  static read = async ({params = {}}) => {
    return await request.get(`/management/rules`, params)
  }

  static resetRuleCache = async () => {
    return await request.get(`/management/reset/rule-cache`, {})
  }

  static resetMerchantCache = async () => {
    return await request.get(`/management/reset/merchant-cache`, {})
  }

  static detail = async ({id, params}) => {
    return await request.get(`/management/rules/${id}/`, params)
  }
}
