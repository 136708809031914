import {DropDown} from '@evet/library'
import {HeadLine, Loader, Pagination, SummaryData, TableLabel} from 'components'
import {CustomDatePicker} from 'form'
import {qs, timeToUnix, useDefaultParams} from 'helpers'
import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {
  CgPlayListCheck,
  FaRegTimesCircle,
  FaSortAmountUpAlt,
  FiDownload,
} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import {DownloadServices as Services} from 'services'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Row} from './Row'

export const PromoCode = inject(
  'PromoStore',
  'UIStore',
  'ProfileStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore
    const {search} = useLocation()
    const history = useHistory()
    const params = qs.parse(search)
    const [paramsAdded, setParamsAdded] = useState(false)
    const {page = 0, from, to} = params
    const startTime = timeToUnix(from)
    const endTime = timeToUnix(to)
    const init = paramsAdded && startTime && endTime
    const [loadingFailed, setLoadingFailed] = useState(false)
    const [loadingSuccess, setLoadingSuccess] = useState(false)

    useDefaultParams({
      callback: setParamsAdded,
      once: {
        search: '[{"field":"worked","q":["True"],"include":true}]',
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    useDeepCompareEffect(() => {
      init &&
        props.PromoStore.read({
          params: {
            ...params,
            page,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.PromoStore, page, params, init, startTime, endTime])
    const {state, meta, list, limit} = props.PromoStore

    const handleTimeChange = dates => {
      if (params.page) delete params['page']
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            ...params,
            from: dates[0],
            to: dates[1],
          })
      )
    }

    const chartData = [
      {label: 'Successfully', value: (meta.all_time || {}).total_successful},
      {label: 'UnSuccessfully', value: (meta.all_time || {}).total_failed},
      {
        label: 'Total Saving',
        value: (meta.all_time || {}).total_saved,
        noColor: true,
      },
    ]

    const labelData = [
      {
        label: 'Successfully',
        icon: <CgPlayListCheck />,
        name: JSON.stringify([{field: 'worked', q: ['True'], include: true}]),
        value: (meta.filtered_stats || {}).total_successful,
      },
      {
        label: 'UnSuccessfully',
        icon: <FaRegTimesCircle />,
        name: JSON.stringify([{field: 'worked', q: ['False'], include: true}]),
        value: (meta.filtered_stats || {}).total_failed,
      },
      {
        label: 'Saving Amount',
        icon: <FaSortAmountUpAlt />,
        value: (meta.filtered_stats || {}).total_saved,
        noClick: true,
      },
    ]

    const handleDownloadFailed = async e => {
      e.preventDefault()
      e.stopPropagation()
      setLoadingFailed(true)
      await Services.downloadFailed({
        params: {
          from: startTime,
          to: endTime,
        },
      })
        .then(() => setLoadingFailed(false))
        .catch(e => e)
    }

    const handleDownloadSuccess = async e => {
      e.preventDefault()
      e.stopPropagation()
      setLoadingSuccess(true)
      await Services.downloadSuccess({
        params: {
          from: startTime,
          to: endTime,
        },
      })
        .then(() => setLoadingSuccess(false))
        .catch(e => e)
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Promo Code" className="sz-30 fw-300">
          <div className="item per-20">
            <DropDown
              icon={<FiDownload style={{padding: 0, margin: 0}} />}
              label="Download Report"
              className="per-100">
              <button disabled={loadingSuccess} onClick={handleDownloadSuccess}>
                {loadingSuccess && <div className="btn-loader" />}
                Successful Coupon Codes
              </button>
              <button disabled={loadingFailed} onClick={handleDownloadFailed}>
                {loadingFailed && <div className="btn-loader" />}
                Failed Coupon Codes
              </button>
            </DropDown>
          </div>
        </HeadLine>

        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-100 horizon right middle">
              <div className="filter per-50 horizon right">
                <CustomDatePicker
                  onChange={handleTimeChange}
                  defaultValue={[new Date(from), new Date(to)]}
                />
              </div>
            </div>
          </div>

          <SummaryData
            className="mt-20"
            chartData={chartData}
            labelData={labelData}
            paramName="search"
            defaultValue="worked"
          />
          {state !== 'done' ? (
            <Loader />
          ) : (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      {/*["averageBasket", "averageDiscount", "totalDiscount", "count", "lastUsedAt"]*/}
                      <TableLabel
                        label="MERCHANT"
                        field="merchant"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="PROMO CODE"
                        field="code"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="# of USAGE"
                        field="count"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="Last Used"
                        field="lastUsedAt"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="TOTAL SAVING AMOUNT"
                        field="totalDiscount"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="AVG SAVING AMOUNT"
                        field="averageDiscount"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="AVG BASKET AMOUNT"
                        field="averageBasket"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="WORKED"
                        field="worked"
                        meta={meta}
                        store={props.PromoStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {list.map(i => (
                    <Row key={'promo-code-' + i.id} data={i} />
                  ))}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={(meta.filtered_stats || {}).total_count}
                limit={limit}
                range={9}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    )
  })
)
