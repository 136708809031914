import {DropDown} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'

export const CountryMenu = inject('UIStore')(
  observer(() => {
    const {country = 'us'} = useParams()

    useEffect(() => {
      document.documentElement.className = ''
      document.documentElement.classList.add(`theme-${country}`)
      console.log('Theme updated...')
    }, [country])

    const changeCountry = async e => {
      e.stopPropagation()
      const country = e.currentTarget.dataset.value
      await localStorage.setItem('country', country)
      window.location = `/${country}`
    }

    return (
      <React.Fragment>
        <DropDown className="right" label="Country">
          <button data-value="us" onClick={changeCountry} className="country">
            <span>US</span>
          </button>
          <button data-value="uk" onClick={changeCountry} className="country">
            <span>UK</span>
          </button>
        </DropDown>
      </React.Fragment>
    )
  })
)
