import {ASide} from 'materials'
import React, {useState} from 'react'
import {
  BsArrowRepeat,
  FaRegUser,
  FiMap,
  GoBrowser,
  MdCompareArrows,
} from 'react-icons/all'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {BrowsingHistory} from './BrowsingHistory'
import {ConversionReport} from './ConversionReport'
import {ShowOnMap} from './Map'
import {Profile} from './Profile'
import {Transaction} from './Transaction'

export const UserProfile = props => {
  const {id, onClose} = props
  const [defaultIndex, setDefaultIndex] = useState(0)
  const handleDefaultIndex = i => setDefaultIndex(i)
  return (
    <ASide onClose={onClose} title={'User ' + id}>
      <Tabs
        className="user-profile-modal"
        selectedIndex={defaultIndex}
        onSelect={() => {}}>
        <TabList className="tab-menu" default={true}>
          <Tab onClick={() => setDefaultIndex(0)}>
            <FaRegUser />
            User Profile
          </Tab>
          <Tab onClick={() => setDefaultIndex(1)}>
            <GoBrowser />
            Website Browsing History
          </Tab>
          <Tab onClick={() => setDefaultIndex(2)}>
            <MdCompareArrows />
            Transaction Report
          </Tab>
          <Tab onClick={() => setDefaultIndex(3)}>
            <BsArrowRepeat />
            Conversion Report
          </Tab>
          <Tab onClick={() => setDefaultIndex(4)}>
            <FiMap />
            Show on Maps
          </Tab>
        </TabList>
        <TabPanel>
          <div className="base">
            <Profile id={id} onDefaultIndex={handleDefaultIndex} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="base">
            <BrowsingHistory id={id} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="base">
            <Transaction id={id} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="base">
            <ConversionReport id={id} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="base">
            <ShowOnMap id={id} />
          </div>
        </TabPanel>
      </Tabs>
    </ASide>
  )
}
