import React from 'react'
import {MdAdd} from 'react-icons/md'
import {Link} from 'react-router-dom'

export const AddButton = props => {
  return props.to ? (
    <Link
      id={props.id}
      to={props.to}
      className={
        'btn add-button horizon middle per-auto center ' + props.className
      }>
      <MdAdd />
      <span>{props.name}</span>
    </Link>
  ) : (
    <button
      id={props.id}
      onClick={props.onClick}
      className={'btn add-button horizon middle ' + props.className}>
      <MdAdd />
      <span>{props.name}</span>
    </button>
  )
}
