import {TextField, withStyles} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

// https://material-ui.com/api/autocomplete/#css
const StyledAutoComplete = withStyles({})(Autocomplete)

export const CustomAutoComplete = props => {
  const {errors, control} = useFormContext()
  const {name, label, className, options, ...rest} = props
  // const defaultValue = options.find(option => option.value === getValues()[name]?.value);

  // useEffect(() => {
  // 	register({name});
  // }, [register, name]);

  // const handleChange = useCallback(
  // 	(_, value) => {
  // 		setValue(name, value);
  // 	},
  // 	[name, setValue]
  // );

  return (
    <React.Fragment>
      <label className={'vertical left ' + className}>
        <span>{label}</span>
        <Controller
          render={({onChange, ...props}) => (
            <StyledAutoComplete
              fullWidth
              options={options}
              getOptionLabel={option =>
                option.label ||
                options.find(i => i.value === option.value).label
              }
              renderOption={option => <span>{option.label}</span>}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="Choose a country"
                  variant="outlined"
                />
              )}
              onChange={(e, data) => onChange(data)}
              {...props}
              {...rest}
            />
          )}
          onChange={([, data]) => data}
          name={name}
          control={control}
        />

        {/*<StyledAutoComplete*/}
        {/*	fullWidth*/}
        {/*	onChange={handleChange}*/}
        {/*	name={name}*/}
        {/*	options={options}*/}
        {/*	getOptionLabel={option => option.label}*/}
        {/*	renderOption={option => <span>{option.label}</span>}*/}
        {/*	renderInput={params => (*/}
        {/*		<TextField*/}
        {/*			{...params}*/}
        {/*			// label={label}*/}
        {/*			variant="outlined"*/}
        {/*		/>*/}
        {/*	)}*/}
        {/*	getOptionSelected={(option, value) => {console.log({option, value})*/}
        {/*	return option.value === value.value}}*/}
        {/*	{...rest}*/}
        {/*/>*/}

        {errors[name] && (
          <div className="form-alert error per-100 p-6 pl-0">
            <p>{errors[name].message}</p>
          </div>
        )}
      </label>
    </React.Fragment>
  )
}
