import React from 'react'
import {Link, useLocation} from 'react-router-dom'

export const Pagination = props => {
  const totalPages = Math.ceil(props.count / props.limit)
  const range = Math.min(totalPages, props.range)
  const center = totalPages > range ? Math.ceil(range / 2) : range
  const start =
    props.page > center
      ? props.page >= totalPages - center
        ? totalPages - range
        : props.page - center
      : 1
  const end = totalPages > range ? start + range : start + range - 1
  const page =
    props.page <= 0 ? 0 : props.page > totalPages ? totalPages : props.page

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const setPage = page => {
    params.set('page', page)
    return params
  }

  const pageNumbers = () => {
    let pages = []
    for (let i = start; i <= end; i++) {
      const q = setPage(i - 1)
      const isActive = i - 1 === parseInt(page)
      pages.push(
        <span key={i} className={isActive ? 'active' : ''}>
          <Link
            className={'page-link ' + (isActive && 'active text-white')}
            to={'?' + q.toString()}>
            {i}
          </Link>
        </span>
      )
    }
    return pages
  }

  const first = '?' + setPage(0)
  const previous = '?' + setPage(parseInt(page) - 1)
  const next = '?' + setPage(parseInt(page) + 1)
  const last = '?' + setPage(totalPages - 1)
  return (
    <div style={{...props.style}} className={props.className}>
      <Link className="page-link" to={first}>
        &laquo;
      </Link>
      {page <= start - 1 ? (
        <span>&lsaquo;</span>
      ) : (
        <Link className="page-link" to={previous}>
          &lsaquo;
        </Link>
      )}
      {pageNumbers()}
      {page >= end - 1 ? (
        <span>&rsaquo;</span>
      ) : (
        <Link className="page-link" to={next}>
          &rsaquo;
        </Link>
      )}
      <Link className="page-link" to={last}>
        &raquo;
      </Link>
    </div>
  )
}
