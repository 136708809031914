import {action, computed, makeObservable, observable, toJS} from 'mobx'
import {Rule as Model, RuleMeta as Meta} from '../models'
import {RuleServices as Service} from '../services'

export default class Store {
  _list = new observable.map()
  _item = {}
  state = 'initial'
  meta = {}

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _list: observable,
      meta: observable,
      state: observable,
      _item: observable,

      list: computed,
      item: computed,

      read: action,
      addOrUpdate: action,
      resetRuleCache: action,
      resetMerchantCache: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get list() {
    return [...this._list.values()]
  }

  get item() {
    return toJS(this._item)
  }

  read({id, params = {}}) {
    const offset = params.page * params.limit

    if (id) {
      this.state = 'getting'
      return Service.detail({id, params}).then(res => {
        const item = new Model(res || {})
        this._list.set(item.id, item)
        this._item = item
        this.state = 'done'
        return item
      })
    } else {
      this.state = 'listing'
      return Service.read({
        params: {...params, offset}, //TODO-samba: change
      }).then(this.fetchSuccess, this.handleError)
    }
  }

  addOrUpdate({data, id = undefined}) {
    if (id)
      return Service.update({id, data}).then(res => {
        this.stores.SystemMessageStore.handleError({message: res.status})
      })
    else
      return Service.create({data}).then(res => {
        this.stores.SystemMessageStore.handleError({message: res.status})
      })
  }

  resetRuleCache() {
    return Service.resetRuleCache().then(res => {
      this.stores.SystemMessageStore.handleError({message: res.status})
    })
  }

  resetMerchantCache() {
    return Service.resetMerchantCache().then(res => {
      this.stores.SystemMessageStore.handleError({message: res.status})
    })
  }

  fetchSuccess = res => {
    if (res.meta) {
      this.meta = new Meta(res.meta)
    }
    if (res.data) {
      this._list = new observable.map()
      res.data.forEach(i => {
        const item = new Model(i || {})
        this._list.set(item.id, item)
      })
    }
    this.state = 'done'
  }

  handleError = error => {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }
}
