import moment from 'moment'
import React, {useEffect} from 'react'
import {useFormContext} from 'react-hook-form'
import {DateRangePicker} from 'rsuite'

const now = moment()
const dayOfWeek = now.day()
const dayOfMonth = now.date()

const {after} = DateRangePicker

const ranges = [
  {label: 'Today', subtractDates: [0, 0]},
  {label: 'Yesterday', subtractDates: [1, 1]},
  {label: 'Last 7', subtractDates: [6, 0]},
  {label: 'Last 14', subtractDates: [13, 0]},
  {label: 'Last 30', subtractDates: [29, 0]},
  {label: 'This Week', subtractDates: [dayOfWeek, 0]},
  {label: 'Last Week', subtractDates: [dayOfWeek + 7, dayOfWeek + 1]},
  {label: 'This Month', subtractDates: [dayOfMonth - 1, 0]},
  {
    label: 'Last Month',
    exactDates: [
      moment().subtract(1, 'months').startOf('month').startOf('days').toDate(),
      moment().subtract(1, 'months').endOf('month').endOf('days').toDate(),
    ],
  },
]

export const CustomDatePicker = props => {
  const {register, setValue} = useFormContext() || {}
  const {name, format, onChange, disabledDate, ...rest} = props

  useEffect(() => {
    if (register) register({name})
  }, [register, name])

  const handleChange = _value => {
    const value = [
      moment(_value[0]).startOf('days').toDate(),
      moment(_value[1]).endOf('days').toDate(),
    ]
    if (register) setValue(name, value)
    else onChange(value)
  }

  const handleRenderValue = (value, format) => {
    return (
      <span>
        {/*{moment(value[0].setHours(0, 0, 0, 0)).format(format)} -*/}
        {/*{moment(value[1].setHours(23, 59, 59, 59)).format(format)}*/}
        {moment(value[0]).format(format) +
          ' - ' +
          moment(value[1]).format(format)}
      </span>
    )
  }
  return (
    <React.Fragment>
      <div className="date-range-picker">
        <DateRangePicker
          {...rest}
          placement="auto"
          format={format || 'MM-DD-YYYY'}
          name={name}
          onChange={handleChange}
          renderValue={handleRenderValue}
          preventOverflow
          disabledDate={disabledDate || after(new Date())}
          ranges={ranges.map(i => ({
            label: i.label,
            value: i.subtractDates
              ? [
                  moment()
                    .subtract(i.subtractDates[0], 'days')
                    .startOf('days')
                    .toDate(),
                  moment()
                    .subtract(i.subtractDates[1], 'days')
                    .endOf('days')
                    .toDate(),
                ]
              : i.exactDates,
          }))}
        />
      </div>
    </React.Fragment>
  )
}
