import {inject, observer} from 'mobx-react'
import {qs} from 'helpers'
import React from 'react'
import {FaCircle} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import {Cell, Pie, PieChart, ResponsiveContainer} from 'recharts'

const COLORS = ['#0088FE', '#00C49F', '#FFBB28']

const Item = props => {
  const {name, onClick, noClick, className} = props

  const handleClick = () => {
    if (!noClick) onClick(name)
  }
  return (
    <div onClick={handleClick} className={className}>
      {props.children}
    </div>
  )
}

export const SummaryData = inject(
  'ProfileStore',
  'UserStore',
  'UIStore'
)(
  observer(props => {
    const {paramName = 'status', chartData, labelData, defaultValue} = props

    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)

    const handleClick = name => {
      if (props.hasOwnProperty('onClick')) props.onClick(name)
      else {
        params[paramName] = name
        history.push('?' + qs.stringify(params))
      }
    }

    const checkActive = name => {
      if ((params[paramName] || defaultValue) === name)
        return 'card item per-22 active'
      else return 'card item per-22'
    }

    return (
      <div
        className={
          'summary-card-base horizon between per-100 middle ' + props.className
        }>
        {labelData.map(i => (
          <Item
            key={'summary-card-' + i.name}
            className={checkActive(i.name)}
            name={i.name}
            onClick={handleClick}
            noClick={i.noClick}>
            <span>{i.label}</span>
            <p className="horizon middle left">
              {' '}
              {i.icon} {i.value}
            </p>

            {!i.noClick && <button className="btn basic">View List</button>}
          </Item>
        ))}

        <div className="card  per-30 chart-base p-0">
          <div style={{width: '100px', height: '120px'}}>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={chartData}
                  innerRadius={20}
                  outerRadius={40}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value">
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <table className="per-50">
            <tbody>
              {chartData.map((entry, index) => (
                <tr key={'summary-' + index}>
                  <td>
                    {!entry.noColor && (
                      <FaCircle
                        className="sz-10"
                        style={{color: COLORS[index % COLORS.length]}}
                      />
                    )}
                  </td>
                  <td>
                    <span className="sz-12 fw-400">{entry.label}</span>
                  </td>
                  <td>
                    <span className="sz-12 fw-600">{entry.value}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  })
)
