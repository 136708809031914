import React from 'react'
import {MdSearch} from 'react-icons/md'

export const SearchInput = props => {
  return (
    <form method="GET" className={props.className}>
      <label className="border-box per-100 search-input horizon between middle">
        <input
          {...props}
          type="text"
          name={props.name || 'search_text'}
          placeholder={props.placeholder || 'Search'}
          className="per-100"
          onClick={props.onClick || null}
          onChange={props.onChange || null}
          onKeyPress={props.onKeyPress || null}
          defaultValue={props.defaultValue}
        />
        <MdSearch className="mr-10" />
      </label>
    </form>
  )
}
