import {inject, observer} from 'mobx-react'
import moment from 'moment'
import React, {useState} from 'react'
import DayPicker, {DateUtils} from 'react-day-picker'
import {MdDateRange} from 'react-icons/md'
import {useHistory, useLocation} from 'react-router-dom'
import {PrimaryButton, SecondaryButton} from 'materials'

export const DateFilter = inject(
  'ProfileStore',
  'UIStore'
)(
  observer(({ProfileStore, UIStore}) => {
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search)
    const {today, yesterday, date} = ProfileStore

    const fromParam = date(
      searchParams.get('from') || UIStore.from || yesterday.format(),
      false
    ).toDate()
    const toParam = date(
      searchParams.get('to') || UIStore.to || today.format(),
      false
    ).toDate()
    const [dates, setDates] = useState({from: fromParam, to: toParam})

    const [selectedFilter, setSelectedFilter] = useState(null)

    const [isOpen, setIsOpen] = useState(false)
    const toggleMenu = e => {
      e.preventDefault()
      e.stopPropagation()
      setIsOpen(!isOpen)
      window.addEventListener('click', () => {
        setIsOpen(false)
      })
    }

    const getDates = dates => {
      if (dates && dates.to && dates.from)
        return {
          to:
            dates.to instanceof Date
              ? new Date(dates.to.setHours(23, 59, 59, 0))
              : dates.to.toDate(),
          from:
            dates.from instanceof Date
              ? new Date(dates.from.setHours(0, 0, 0, 0))
              : dates.from.toDate(),
        }
      else
        return {
          from: new Date(today.format('YYYY-MM-DDT00:00:00')),
          to: new Date(today.format('YYYY-MM-DDT23:59:59')),
        } // today
    }

    const {from, to} = getDates(dates)
    const modifiers = {start: from, end: to}

    const handleDateChange = day => {
      setSelectedFilter(null)
      setDates(DateUtils.addDayToRange(day, getDates(dates)))
    }

    const applyTimeFilter = dates => {
      if (dates.from && dates.to) {
        searchParams.set('from', new Date(from))
        searchParams.set('to', new Date(to))
        searchParams.delete('page')
        UIStore.from = dates.from
        UIStore.to = dates.to
        history.push('?' + searchParams)
      } else {
        searchParams.set('from', moment.utc(yesterday).toISOString())
        searchParams.set('to', moment.utc(today).toISOString())
        history.push('?' + searchParams)
      }
    }

    const resetTimeFilter = dates => {
      searchParams.set('from', moment(dates.from).toISOString())
      searchParams.set('to', moment(dates.to).toISOString())
      searchParams.delete('page')
      UIStore.from = dates.from
      UIStore.to = dates.to
      history.push('?' + searchParams)
    }

    const handleApplyFilter = () => {
      applyTimeFilter(dates)
      setIsOpen(false)
    }

    const handleReset = () => {
      const date = {from: yesterday.toDate(), to: today.toDate()}
      setSelectedFilter(null)
      setDates(date)
      resetTimeFilter(date)
      setIsOpen(false)
    }

    const selectPreDefinedRange = rangeKey => {
      //const today = new Date();
      setSelectedFilter(rangeKey)
      switch (rangeKey) {
        default:
        case 'today':
          // console.log('open today', {from: yesterday.toDate(), to: today.toDate()})
          //setDates({from: today.toDate(), to: today.toDate()});
          //setDates({from: yesterday.toDate(), to: today.toDate()});
          setDates({
            //from: date(yesterday).add(-1, 'days').toDate(),
            from: new Date(today.format('YYYY-MM-DDT00:00:00')),
            to: new Date(today.format('YYYY-MM-DDT23:59:00')),
          })
          break

        case 'yesterday':
          setDates({
            //from: date(yesterday).add(-1, 'days').toDate(),
            from: new Date(yesterday.format('YYYY-MM-DDT00:00:00')),
            to: new Date(yesterday.format('YYYY-MM-DDT23:59:00')),
          })
          break

        case 'last_7_days':
          setDates({
            from: date(today).add(-7, 'days').toDate(),
            to: today.toDate(),
          })
          break

        case 'last_14_days':
          setDates({
            from: date(today).add(-14, 'days'),
            to: today,
          })
          break

        case 'last_30_days':
          setDates({
            from: date(today).add(-30, 'days'),
            to: today,
          })
          break

        case 'this_week':
          setDates({
            from: date(today).startOf('isoWeek').toDate(),
            to: today,
          })
          break

        case 'last_week':
          setDates({
            from: date(today).subtract(1, 'weeks').startOf('isoWeek').toDate(),
            to: date(today).subtract(1, 'weeks').endOf('isoWeek').toDate(),
          })
          break

        case 'this_month':
          setDates({
            from: date(today).startOf('month').toDate(),
            to: today,
          })
          break

        case 'last_month':
          setDates({
            from: date(today).subtract(1, 'months').startOf('month').toDate(),
            to: date(today).subtract(1, 'months').endOf('month').toDate(),
          })
          break
      }
    }

    return (
      <div className={'date-filter per-100'}>
        <div className="date-filter__content">
          {isOpen && (
            <div className="date-filter__container">
              <div
                className="calendar"
                onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  e.cancelBubble = true
                  return false
                }}>
                <DayPicker
                  //className="Selectable horizon between"
                  utc={true}
                  numberOfMonths={2}
                  selectedDays={[from, {from, to}]}
                  disabledDays={{after: today.toDate()}}
                  month={from}
                  modifiers={modifiers}
                  onDayClick={handleDateChange}
                  firstDayOfWeek={1}
                />
                <div className="date-filter__container__special-select">
                  <div className="time-picker">
                    <div className="date-filter__container__special-select__title">
                      <h2>Predefined Filters</h2>
                    </div>
                    <ul id="scrollBar">
                      <li
                        className={selectedFilter === 'today' ? 'active' : ''}>
                        <button onClick={() => selectPreDefinedRange('today')}>
                          Today
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'yesterday' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('yesterday')}>
                          Yesterday
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'last_7_days' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('last_7_days')}>
                          Last 7 Days
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'last_14_days' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('last_14_days')}>
                          Last 14 Days
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'last_30_days' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('last_30_days')}>
                          Last 30 Days
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'this_week' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('this_week')}>
                          This Week
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'last_week' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('last_week')}>
                          Last Week
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'this_month' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('this_month')}>
                          This Month
                        </button>
                      </li>
                      <li
                        className={
                          selectedFilter === 'last_month' ? 'active' : ''
                        }>
                        <button
                          onClick={() => selectPreDefinedRange('last_month')}>
                          Last Month
                        </button>
                      </li>
                    </ul>
                    <div className="date-filter__controls per-100">
                      <SecondaryButton onClick={handleReset} name="Reset" />
                      <PrimaryButton onClick={handleApplyFilter} name="Apply" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="date-filter__place-holder">
            <button
              onClick={() => {
                /*do not delete*/
              }}
              style={{display: 'none'}}
            />
            <button
              className="date-filter__label per-100 horizon left"
              onClick={toggleMenu}>
              <MdDateRange />
              <span>
                {date(from).format('MMM D, YYYY')} -{' '}
                {date(to).format('MMM D, YYYY')}
              </span>
            </button>
          </div>
        </div>
      </div>
    )
  })
)
