import {inject, observer} from 'mobx-react'
import React from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {ManagementMenu, Menu} from '../'
import {CountryMenu} from './CountryMenu/CountryMenu'
import {FaSignOutAlt} from 'react-icons/all'
import {Brand, DropDown} from '@evet/library'

export const CustomHeader = inject('AuthStore')(
  observer(props => {
    const history = useHistory()
    const {country = 'us'} = useParams()
    const handleHome = () => {
      history.push(`/${country}`)
    }

    // const toggleMenu = () => {
    //   document.getElementById('layout').classList.toggle('show')
    // }

    const {me} = props.AuthStore
    const is_admin = me && me.checkProjectAccess() === 'ADMIN'
    return (
      <header className="horizon stretch between per-100">
        <div className="left ">
          <Brand onClick={handleHome}>Hero</Brand>
          {is_admin && <Menu />}
          <ManagementMenu />
        </div>
        <div className="right">
          <CountryMenu />
          {me && (
            <DropDown className="right" label={me.name} icon={me.name[0]}>
              <Link to="/logout">
                <FaSignOutAlt /> Logout
              </Link>
            </DropDown>
          )}
        </div>
      </header>
    )
  })
)
