import {inject, observer} from 'mobx-react'
import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {AuthLoader, ScrollToTop} from 'components'
import {Routing} from 'routing'

export const App = inject('AuthStore')(
  observer(props => {
    useDeepCompareEffect(() => {
      if (!props.AuthStore.authenticated)
        window.location.href = process.env.REACT_APP_AUTH
      else if (!['/logout', '/pending'].includes(window.location.pathname))
        props.AuthStore.handleAuth().then()
    }, [props.AuthStore])

    const {authenticated, me} = props.AuthStore
    // TODO IS ADMIN CONTROL REMOVED BECAUSE THIS PROJECT HAS NO CLIENT SIDE
    // if (me && me.checkProjectAccess() !== 'ADMIN')
    //   window.location.href = process.env.REACT_APP_AUTH + '?access_denied'

    return (
      <React.Fragment>
        {authenticated && !me.id ? (
          <AuthLoader />
        ) : (
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" component={Routing} />
            </Switch>
          </Router>
        )}
      </React.Fragment>
    )
  })
)
