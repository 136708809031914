import React from 'react'
import logo from 'images/logo-fligram.png'

export const AuthLoader = props => {
  return props.padding ? (
    <div className="per-100 h-100 horizon center middle ">
      <div className="logo-load">
        <img alt="loader" src={logo} />
      </div>
    </div>
  ) : (
    <div className="per-100 h-100 horizon center middle">
      <div className="logo-load">
        <img alt="loader" src={logo} />
      </div>
    </div>
  )
}
