import InputBase from '@material-ui/core/InputBase'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ede9f1',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#ccc',
    },
  },
}))(InputBase)

export const CCCVV = props => {
  const {register, errors} = useFormContext()
  const {className, label, name, ...rest} = props

  return (
    <React.Fragment>
      <label className={className}>
        <span>{label}</span>
        <CustomInput
          {...rest}
          name={name}
          className="per-100"
          variant="outlined"
          fullWidth
          inputRef={register()}
          inputProps={{
            onKeyUp: e => {
              const value = e.target.value
                .replace(/ /g, '')
                .replace(/[^0-9]+/gi, '')
              const ref = e.currentTarget
              const selectionStart = ref.selectionStart
              const selectionEnd = ref.selectionEnd

              e.target.value = value.replace(/^([0-9]{4}).*/, '$1')

              if (selectionStart < value.length) {
                ref.selectionStart = selectionStart
                ref.selectionEnd = selectionEnd
              }

              return false
            },
          }}
        />
        {errors[name] && (
          <div className="error per-100">
            <span>{errors[name].message}</span>
          </div>
        )}
      </label>
    </React.Fragment>
  )
}
