import InputBase from '@material-ui/core/InputBase'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const CustomInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#ede9f1',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#ccc',
    },
  },
}))(InputBase)

export const CCNumber = props => {
  const {register, errors} = useFormContext()
  const {className, label, name, ...rest} = props

  const visaPattern = /^(4[0-9]{12}(?:[0-9]{3})?)$/
  // const mastPattern = /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/;
  const amexPattern = /^(3[47][0-9]{13})$/
  // const dinePattern = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  // const discPattern = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
  // const jcbcPattern = /^(?:2131|1800|35\d{3})\d{11}$/;

  return (
    <React.Fragment>
      <label className={className}>
        <span>{label}</span>
        <CustomInput
          {...rest}
          name={name}
          className="per-100"
          variant="outlined"
          fullWidth
          inputRef={register}
          inputProps={{
            onKeyUp: e => {
              const value = e.target.value
                .replace(/ /g, '')
                .replace(/[^0-9]+/gi, '')
              const ref = e.currentTarget
              const selectionStart = ref.selectionStart
              const selectionEnd = ref.selectionEnd

              // console.log(selectionStart, value.length, key, code)

              const isVisa = visaPattern.test(value) === true
              // const isMast = mastPattern.test(value) === true;
              const isAmex = amexPattern.test(value) === true
              // const isDisc = discPattern.test(value) === true;
              // const isDine = dinePattern.test(value) === true;
              // const isJCBC = jcbcPattern.test(value) === true;

              //console.log({isVisa, isMast, isAmex, isDisc, isDine, isJCBC});

              //if (value.match(/(.{4})/g))
              e.target.value = value
                .replace(/(.{4})/g, '$1 ')
                .replace(/^([0-9\s]{19}).*/, '$1')
              //else {

              if (isVisa)
                e.target.value = e.target.value.replace(visaPattern, '$1')
              if (isAmex)
                e.target.value = e.target.value.replace(amexPattern, '$1')

              if (selectionStart < value.length) {
                ref.selectionStart = selectionStart
                ref.selectionEnd = selectionEnd
              }

              return false
            },
          }}
        />
        {errors[name] && (
          <div className="error per-100">
            <span>{errors[name].message}</span>
          </div>
        )}
      </label>
    </React.Fragment>
  )
}
