import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({params = {}}) => {
    // const myParams = {...params, isSuccessful: true}
    // if (!myParams.userID) delete myParams['userID']

    return await request.get(`/coupons/`, params)
  }

  static update = async () => null

  static delete = async () => null

  static detail = async ({params}) => {
    return await request.get(`/coupons/`, params)
  }

  static selectable = async ({field}) =>
    await request.get(`/coupons/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/coupons/searchable`, {field, q})
}
