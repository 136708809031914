import {Slider, withStyles} from '@material-ui/core'
import React, {useCallback, useEffect, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {QuickTips} from 'materials'

// https://material-ui.com/api/slider/#css

const StyledSlider = withStyles({
  root: {
    color: '#7000ae',
    height: 8,
    width: '100%',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -8,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {},
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {},
})(Slider)

export const CustomSlider = props => {
  const {register, setValue, watch} = useFormContext()
  const {
    name,
    className,
    max,
    min,
    marks,
    valueLabelDisplay,
    label,
    info,
    ...rest
  } = props
  const value = watch(name)
  const [tmpValue, setTmpValue] = useState(value)

  useEffect(() => {
    register({name})
  }, [register, name])

  useEffect(() => {
    if (min && max) {
      const changedRange = Math.max(min, Math.min(max, value))
      setValue(name, changedRange)
      setTmpValue(changedRange)
    }
  }, [max, min, name, value, setValue])

  const submitChange = useCallback(
    (_, value) => {
      setValue(name, value)
      setTmpValue(value)
      // setCurrentValue(value);
    },
    [name, setValue]
  )

  const handleChange = useCallback((_, value) => {
    // setValue(name, value);
    // setCurrentValue(value);
    setTmpValue(value)
  }, [])

  return (
    <React.Fragment>
      <label className={' vertical ' + className}>
        <span className="horizon left middle per-100">
          {label}
          {info && <QuickTips className="horizon left top" comment={info} />}
        </span>
        <StyledSlider
          {...rest}
          name={name}
          max={max}
          min={min}
          onChangeCommitted={submitChange}
          onChange={handleChange}
          valueLabelDisplay={valueLabelDisplay || 'auto'}
          value={tmpValue}
        />
        <div className="horizon between middle per-100">
          {marks && <span className="sz-12 f-300 c-content">{min}</span>}
          {marks && <span className="sz-12 f-300 c-content">{max}</span>}
        </div>
      </label>
    </React.Fragment>
  )
}
