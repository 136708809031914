import {DateTime} from 'components'
import {EmptyBoard, MoreLess} from 'materials'
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import {qs} from 'helpers'
import React from 'react'
import {RiStickyNote2Line} from 'react-icons/all'
import {useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Loader} from '../Loader'

export const ConversionReport = inject(
  'UIStore',
  'ConversionStore',
  'ProfileStore'
)(
  observer(props => {
    const {id} = props
    const {search} = useLocation()
    const params = qs.parse(search)

    const {today, daysAgo} = props.ProfileStore

    const {item, detailState} = props.ConversionStore

    const from = new Date(daysAgo(30).startOf('days').toDate())
    const to = new Date(today.endOf('days').toDate())

    const startTime =
      moment(from).subtract(from.getTimezoneOffset(), 'minutes').unix() * 1000
    const endTime =
      moment(to).subtract(to.getTimezoneOffset(), 'minutes').unix() * 1000

    useDeepCompareEffect(() => {
      props.ConversionStore.read({
        params: {
          ...params,
          search: JSON.stringify([{field: 'user_id', q: [id], include: true}]),
          from: startTime,
          to: endTime,
        },
        onModal: true,
      })
    }, [props.ConversionStore, id])

    // const checkCodeUsed = (successful_codes, used_code) => {
    //   for (let item of successful_codes) {
    //     if (item.code === used_code?.code) return true;
    //   }
    //   return false;
    // };

    return (
      <div className="p-10 vertical left stretch">
        {detailState !== 'done' ? (
          <div className="mb-40 mt-20">
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            {item.length > 0 ? (
              <React.Fragment>
                <div
                  id="scrollBar"
                  className="horizon between stretch per-100 modal-scroll-body mt-10">
                  <table className="list-table-modal mt-20">
                    <thead>
                      <tr>
                        <td>TIME</td>
                        <td>MERCHANT</td>
                        <td>COMMISSION</td>
                        <td>SALES AMOUNT</td>
                        <td>SALES COUNT</td>
                        <td>RETURN</td>
                        <td>TRANSACTIONS</td>
                      </tr>
                    </thead>
                    <tbody>
                      {item.map(
                        ({
                          id,
                          merchant_name,
                          return_count,
                          sales_count,
                          timestamp,
                          total_amount,
                          total_commission,
                          transaction_count,
                        }) => (
                          <tr key={'conversions-' + id}>
                            <td nowrap={'nowrap'}>
                              <DateTime
                                timestamp={timestamp}
                                native
                                format={'MMM D YYYY | hh:mm A'}
                              />
                            </td>
                            <td nowrap={'nowrap'}>
                              <MoreLess content={merchant_name} maxLimit={20} />
                            </td>
                            <td nowrap={'nowrap'}>{total_commission}</td>
                            <td>{total_amount}</td>
                            <td>{sales_count}</td>
                            <td>{return_count}</td>
                            <td>{transaction_count}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </React.Fragment>
            ) : (
              <div className="per-100 vertical center middle">
                <EmptyBoard
                  icon={<RiStickyNote2Line />}
                  description="This user has no conversion history for this month"
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    )
  })
)
