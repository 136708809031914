import React, {useState} from 'react'
import {GoAlert} from 'react-icons/go'

export const InputElement = React.forwardRef((props, ref) => {
  const errors = props.errors || {}
  const messages = props.messages || {}
  const [className, setClassName] = useState(props.className || '')

  const handleFocus = e => {
    setClassName(className.replace(/ focused/g, '') + ' focused')
  }
  const handleBlur = e => {
    const el = e.currentTarget
    const value = el.value
    if (!value.length) setClassName(className.replace(/ focused/g, ''))
  }
  const handleChange = e => {
    const el = e.currentTarget
    const value = el.value
    if (value) {
      setClassName(className.replace(/ focused/g, '') + ' focused')
    }
    if (props.hasOwnProperty('onChange')) return props.onChange(e)
  }
  const handleKeyUp = e => {
    const el = e.currentTarget
    const value = el.value
    if (value && props.maxLength) {
      if (value.length > Number(props.maxLength)) return false
    }
  }
  return (
    <React.Fragment>
      <label className="per-100">
        <input
          type={props.type || 'text'}
          placeholder={props.placeholder}
          name={props.name}
          className={className}
          defaultValue={props.defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyUp}
          ref={ref}
          disabled={props.disabled}
        />
      </label>
      {errors[props.name] && (
        <div className="alert-box form-alert error per-100 p-6">
          <div className="icon">
            <GoAlert />
          </div>
          <p>
            {messages[errors[props.name].type] || errors[props.name].message}
          </p>
        </div>
      )}
    </React.Fragment>
  )
})
