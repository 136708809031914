import InputBase from '@material-ui/core/InputBase'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {useFormContext} from 'react-hook-form'

const StyledInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {},
}))(InputBase)

const CustomRadioButton = React.forwardRef((props, ref) => {
  return (
    <React.Fragment>
      <label className="horizon left  per-100">
        <StyledInput
          {...props}
          name={props.name}
          style={{width: '20px'}}
          variant="outlined"
          placeholder={props.placeholder}
          inputRef={ref}
          inputProps={{
            type: 'radio',
            defaultChecked: props.value === props.defaultChecked,
          }}
        />
        <p className=" ml-10 sz-14 f-400">{props.label}</p>
      </label>
    </React.Fragment>
  )
})

export const CustomRadioGroup = props => {
  const {register, errors} = useFormContext()
  const {className, label, options, name, ...rest} = props

  return (
    <div>
      <div className={className}>
        {(options || []).map((i, k) => (
          <div className="mb-10 " key={'radio-' + props.name + '-' + i.value}>
            <CustomRadioButton
              {...rest}
              name={name}
              ref={register}
              label={i.label}
              value={i.value}
            />
          </div>
        ))}
      </div>
      {errors[name] && (
        <div className="error per-100">
          <span>{errors[name].message}</span>
        </div>
      )}
    </div>
  )
}
