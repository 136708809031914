import {HeadLine, Loader, Pagination} from 'components'
import {CustomInput, CustomSelect, Form} from 'form'
import {qs} from 'helpers'
import {AddButton} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useEffect, useRef, useState} from 'react'
import cookie from 'react-cookies'
import {MdCached} from 'react-icons/all'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Add} from './Add'
import {Item} from './Item'

const APP_NAME = process.env.REACT_APP_NAME || 'project'

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const Rules = inject(
  'RuleStore',
  'UIStore'
)(
  observer(props => {
    const history = useHistory()
    const {page, country = 'us'} = useParams()
    const {search} = useLocation()
    const params = {page: 0, limit: 10, ...qs.parse(search)}
    const isAdding = ['add', 'edit'].includes(page)
    const [qsTimer, setTimer] = useState(0)
    const [isRuleDelay, setRuleDelay] = useState(
      cookie.load(APP_NAME + '_reset_rule_cache') || false
    )
    const [isMerchantDelay, setMerchantDelay] = useState(
      cookie.load(APP_NAME + '_reset_merchant_cache') || false
    )

    useInterval(() => {
      const data = cookie.load(APP_NAME + '_reset_rule_cache')
      setRuleDelay(data || false)
    }, 5000)

    useInterval(() => {
      const data = cookie.load(APP_NAME + '_reset_merchant_cache')
      setMerchantDelay(data || false)
    }, 5000)

    // const [sortBy, setSortBy] = useState('time-desc')
    // const sorts = {
    //   'time-asc': (a, b) => a.timestamp - b.timestamp,
    //   'time-desc': (a, b) => b.timestamp - a.timestamp,
    // }
    // const handleSort = e => setSortBy(e.target.value)

    useDeepCompareEffect(() => {
      props.RuleStore.read({params})
    }, [props.RuleStore, params])

    const handleDetail = async id => {
      history.push({pathname: `/${country}/rules/edit/${id}`, search})
    }

    const {state, meta, list} = props.RuleStore
    // const list = _list.sort(sorts[sortBy])

    const handleClose = () => {
      history.push({pathname: `/${country}/rules`, search})
    }

    const handleCacheReset = async () => {
      await props.RuleStore.resetRuleCache()

      cookie.save(APP_NAME + '_reset_rule_cache', true, {
        path: '/',
        maxAge: 300,
      })
      setRuleDelay(true)
    }

    const handleMerchantReset = async () => {
      await props.RuleStore.resetMerchantCache()

      cookie.save(APP_NAME + '_reset_merchant_cache', true, {
        path: '/',
        maxAge: 300,
      })
      setMerchantDelay(true)
    }

    const handleSubmit = data => {
      data = {...params, ...data}
      if (qsTimer) clearTimeout(qsTimer)
      const timer = setTimeout(() => {
        history.push('?' + qs.stringify(data))
      }, 500)
      setTimer(timer)
    }

    return (
      <section className="vertical center">
        <HeadLine title="Rules" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item">
              <p>Active Rules :</p>
              <span>{meta.total_count || '-'}</span>
            </div>
            <div className="item">
              <p>Reset :</p>
              <div className="horizon left middle">
                <button
                  className="btn primary small"
                  disabled={isRuleDelay}
                  onClick={handleCacheReset}>
                  <MdCached className="mr-6" />
                  Rule Cache
                </button>
                <button
                  className="btn primary small ml-6"
                  disabled={isMerchantDelay}
                  onClick={handleMerchantReset}>
                  <MdCached className="mr-6" />
                  Merchant Cache
                </button>
              </div>
            </div>
            <div className="item">
              <AddButton name="New Rule" to={`/${country}/rules/add`} />
            </div>
          </div>
        </HeadLine>

        {isAdding && (
          <Add loading={state === 'getting'} onClose={handleClose} />
        )}

        <div className="container mt-20 vertical top between">
          <Form
            onSubmit={handleSubmit}
            className="horizon between middle per-100"
            submitOnChange
            defaultValues={{
              ...params,
            }}>
            <div className="horizon left middle per-50">
              <CustomSelect
                className="mr-20"
                label="Sort By"
                name="sort_by"
                options={[
                  {label: 'Time Ascending', value: 'ASC'},
                  {label: 'Time Descending', value: 'DESC'},
                ]}
              />
              <CustomSelect
                label="Page Type"
                name="type"
                options={[
                  {label: 'Shopify', value: 'shopify'},
                  {label: 'Magento', value: 'magento'},
                  {label: 'Custom', value: 'custom'},
                ]}
              />
            </div>
            <div className="vertical right ">
              <CustomInput icon="search" name="q" placeholder="Domain" />
            </div>
            {/* <TimePicker /> */}
          </Form>

          {state === 'listing' ? (
            <Loader />
          ) : (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>DOMAIN</td>
                    <td>ID</td>
                    <td>PAGE TYPE</td>
                  </tr>
                </thead>
                <tbody>
                  {list.map(i => (
                    <Item
                      key={'extensions-' + i.id}
                      data={i}
                      onDetail={handleDetail}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={params.page || 0}
                count={meta.total_count}
                limit={params.limit}
                range={9}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    )
  })
)
