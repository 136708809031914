import {Switch} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'

// https://material-ui.com/api/switch/#css

const StyledSwitch = withStyles({
  switchBase: {
    color: 'blue',
    '&$checked': {
      color: 'green',
    },
    '&$checked + $track': {
      backgroundColor: 'violet',
    },
  },
  checked: {},
  track: {},
})(Switch)

export const CustomSwitch = props => {
  const {control} = useFormContext()
  const {label, name, className, ...rest} = props

  return (
    <React.Fragment>
      <label className={className + ' horizon left middle'}>
        <Controller
          as={<StyledSwitch {...rest} inputProps={{type: 'checkbox'}} />}
          name={name}
          control={control}
        />
        {/*<StyledSwitch {...rest} inputRef={register} name={name} inputProps={{type: 'checkbox'}}/>*/}
        <p className="ml-10 sz-14 f-400">{label}</p>
      </label>
    </React.Fragment>
  )
}
