import {computed, makeObservable, observable} from 'mobx'

export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class User {
  user_id = ''
  sessionID = ''

  constructor(data) {
    if (data) {
      Object.entries(data).forEach(([k, i]) => (this[k] = i))
      const address = this.location_data || {}
      Object.entries(address).forEach(([k, i]) => (this[k] = i))

      makeObservable(this, {
        user_id: observable,
        sessionID: observable,
        timestamp: observable,
        location_data: observable,

        id: computed,
        session_id: computed,
      })
    }
  }

  get id() {
    return this['user_id']
  }

  get session_id() {
    return this['sessionID']
  }
}
