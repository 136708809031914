import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdHome} from 'react-icons/md'
import {useHistory, useParams} from 'react-router-dom'

export const HeadLine = inject(
  'UserStore',
  'UIStore'
)(
  observer(props => {
    const history = useHistory()
    const {country} = useParams()
    const handleHome = () => {
      history.push(`/${country}`)
    }
    return (
      <div className="headline">
        <div className="container pt-20 pb-20 horizon between middle">
          <div className="title vertical left ">
            <div className="breadcrumb">
              <button
                onClick={handleHome}
                style={{
                  border: 0,
                  background: 'transparent',
                  padding: 0,
                  cursor: 'pointer',
                }}>
                <MdHome />
                Dashboard
              </button>
            </div>
            <div className="website">
              <h2 className={props.className}>{props.title}</h2>
            </div>
          </div>
          {props.children}
        </div>
      </div>
    )
  })
)
