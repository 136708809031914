import {DateTime, HeadLine, Loader, Pagination, TableLabel} from 'components'
import {CustomDatePicker} from 'form'
import {qs, timeToUnix, useDefaultParams} from 'helpers'
import {MoreLess, UserIdButton} from 'materials'
import {inject, observer} from 'mobx-react'
import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const Conversions = inject(
  'ProfileStore',
  'ConversionStore',
  'UIStore'
)(
  observer(props => {
    const {today, daysAgo} = props.ProfileStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [paramsAdded, setParamsAdded] = useState(false)
    const {page = 0, from, to} = params

    const startTime = timeToUnix(from)
    const endTime = timeToUnix(to)
    const init = paramsAdded && startTime && endTime

    useDefaultParams({
      callback: setParamsAdded,
      once: {
        sort: '[{"field":"timestamp","direction":"DESC"}]',
      },
      every: {
        from: props.UIStore.from || daysAgo(7).startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    useDeepCompareEffect(() => {
      init &&
        props.ConversionStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.ConversionStore, page, params, init, startTime, endTime])
    const {state, meta, list, stats, statsState} = props.ConversionStore

    const handleTimeChange = dates => {
      if (params.page) delete params['page']
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            ...params,
            from: dates[0],
            to: dates[1],
          })
      )
    }

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Conversions Report" className="sz-30 fw-300">
          <div className="options horizon right middle">
            <div className="item">
              <p>Total Sales</p>
              {statsState === 'listing' ? (
                <div className="btn-loader"> </div>
              ) : (
                <span>{stats.total_sales_formatted}</span>
              )}
            </div>
            <div className="item">
              <p>Total Commission</p>
              {statsState === 'listing' ? (
                <div className="btn-loader"> </div>
              ) : (
                <span>{stats.total_commission_formatted}</span>
              )}
            </div>
          </div>
        </HeadLine>
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-50 horizon left middle" />
            <div className="filter per-50 horizon right">
              <CustomDatePicker
                name="date_range"
                onChange={handleTimeChange}
                defaultValue={[new Date(from), new Date(to)]}
              />
            </div>
          </div>

          {state !== 'listing' ? (
            <React.Fragment>
              <table className="list-table mt-20">
                <thead>
                  <tr>
                    <td>
                      <TableLabel
                        label="TIME"
                        field="timestamp"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="MERCHANT"
                        field="merchant_name"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="COMMISSION"
                        field="total_commission"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="SALES AMOUNT"
                        field="total_amount"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="SALES COUNT"
                        field="sales_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="RETURN"
                        field="return_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="TRANSACTIONS"
                        field="transaction_count"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                    <td>
                      <TableLabel
                        label="USER ID"
                        field="user_id"
                        meta={meta}
                        store={props.ConversionStore}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {state === 'done' &&
                    list.map(
                      ({
                        id,
                        merchant_name,
                        return_count,
                        sales_count,
                        timestamp,
                        total_amount,
                        total_commission,
                        transaction_count,
                        user_id,
                      }) => (
                        <tr key={'conversions-' + id}>
                          <td nowrap={'nowrap'}>
                            <DateTime
                              timestamp={timestamp}
                              native
                              format={'MMM D YYYY | hh:mm A'}
                            />
                          </td>
                          <td nowrap={'nowrap'}>
                            <MoreLess content={merchant_name} maxLimit={20} />
                          </td>
                          <td nowrap={'nowrap'}>{total_commission}</td>
                          <td>{total_amount}</td>
                          <td>{sales_count}</td>
                          <td>{return_count}</td>
                          <td>{transaction_count}</td>
                          <td className="vertical left middle p-0">
                            <UserIdButton id={user_id} />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <Pagination
                className="pagination mt-30 mb-40"
                page={page || 0}
                count={meta.total_count}
                limit={100}
                range={9}
              />
            </React.Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </section>
    )
  })
)
