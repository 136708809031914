import {action, computed, makeObservable, observable} from 'mobx'
import moment from 'moment'
import {Profile as Model} from '../models'
import {ProfileServices as Service} from '../services'

export default class ProfileStore {
  _profile = new Model({})

  constructor(Stores) {
    this.stores = Stores

    makeObservable(this, {
      _profile: observable,

      today: computed,
      yesterday: computed,

      profile: computed,

      // create: action,
      // read: action,
      // update: action,
      // patch: action,
      daysAgo: action,

      fetchSuccess: action.bound,
      handleError: action.bound,
    })
  }

  get profile() {
    return this._profile
  }

  get client_id() {
    const {me} = this.stores.AuthStore
    const {product} = me
    const {client_id} = product ? product.data : {client_id: 0}
    return client_id
  }

  get today() {
    const m =
      this._profile && this._profile.URLUTCOffset
        ? moment().utcOffset(this._profile.URLUTCOffset)
        : moment()
    return m.add(0)
  }

  get yesterday() {
    const m =
      this._profile && this._profile.URLUTCOffset
        ? moment().utcOffset(this._profile.URLUTCOffset)
        : moment()
    return m.add(-1, 'days')
  }

  daysAgo = (days = 0) => {
    const m =
      this._profile && this._profile.URLUTCOffset
        ? moment().utcOffset(this._profile.URLUTCOffset)
        : moment()
    return m.add(-days, 'days')
  }

  read = async () => {
    // if (!this.client_id) return this.stores.AuthStore.signOut()
    Service.read({client_id: this.client_id}).then(
      this.fetchSuccess,
      this.handleError
    )
  }

  fetchSuccess(res) {
    if (res.item) this._profile = new Model(res.item || {})
    this.state = 'done'
  }

  handleError(error) {
    this.state = 'error'
    return this.stores.SystemMessageStore.handleError(error)
  }

  date = (time, byURL = false) => {
    return this._profile
      ? byURL
        ? moment(time).utcOffset(this._profile.URLUTCOffset)
        : moment(time).utcOffset(this._profile.UTCOffset)
      : moment(time)
  }
}
