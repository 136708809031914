import {HeadLine, Loader, SummaryData, TableLabel} from 'components'
import FileSaver from 'file-saver'
import {CustomDatePicker} from 'form'
import {qs, timeToUnix, useDefaultParams} from 'helpers'
import * as get from 'lodash.get' // lodash get required for nested objects
import {inject, observer} from 'mobx-react'
import moment from 'moment'
import React, {useState} from 'react'
import {
  AiOutlineExport,
  HiOutlineFolderDownload,
  MdRemoveFromQueue,
  VscVmActive,
} from 'react-icons/all'
import {useHistory, useLocation} from 'react-router-dom'
import useDeepCompareEffect from 'use-deep-compare-effect'
import {Row} from './Row'

export const ExtensionInstalls = inject(
  'ProfileStore',
  'UserStore',
  'UIStore'
)(
  observer(props => {
    const {today, yesterday} = props.ProfileStore
    const history = useHistory()
    const {search} = useLocation()
    const params = qs.parse(search)
    const [paramsAdded, setParamsAdded] = useState(false)
    const {page = 0, from, to} = params

    const startTime = timeToUnix(from)
    const endTime = timeToUnix(to)
    const init = paramsAdded && startTime && endTime

    useDefaultParams({
      callback: setParamsAdded,
      once: {
        sort: JSON.stringify([{field: 'timestamp', direction: 'DESC'}]),
      },
      every: {
        from: props.UIStore.from || yesterday.startOf('days').toDate(),
        to: props.UIStore.to || today.endOf('days').toDate(),
      },
    })

    useDeepCompareEffect(() => {
      init &&
        props.UserStore.read({
          page,
          params: {
            ...params,
            from: startTime,
            to: endTime,
          },
        })
    }, [props.UserStore, page, params, init, startTime, endTime])
    const {state, meta, list} = props.UserStore

    const handleTimeChange = dates => {
      if (params.page) delete params['page']
      props.UIStore.from = dates[0]
      props.UIStore.to = dates[1]
      history.push(
        '?' +
          qs.stringify({
            ...params,
            from: dates[0],
            to: dates[1],
          })
      )
    }

    const handleExport = () => {
      const headers = [
        'timestamp',
        'user_id',
        'ip_address',
        'location_data.city',
        'location_data.region',
        'location_data.country',
      ]
      const csv = ['Install Date, User Id, Ip Address, City, State, Country']
      list.forEach(item => {
        const row = []
        headers.forEach(label =>
          label !== 'timestamp'
            ? row.push(get(item, label))
            : row.push(
                moment.utc(get(item, label)).format('MMM D YYYY hh:mm A')
              )
        )
        csv.push(row.join(','))
      })

      const csvData = new Blob([csv.join('\r\n')], {
        type: 'text/csv;charset=utf-8;',
      })
      FileSaver.saveAs(csvData, 'removed_extension_installs_export.csv')
    }

    const chartData = [
      {
        label: '# of Download',
        name: 'all',
        value: (meta.all_time || {}).total_count,
      },
      {
        label: 'Active',
        name: 'active',
        value: (meta.all_time || {}).active_count,
      },
      {
        label: 'Removed',
        name: 'removed',
        value: (meta.all_time || {}).removed_count,
      },
    ]

    const labelData = [
      {
        label: '# of Download',
        icon: <HiOutlineFolderDownload />,
        name: 'all',
        value: (meta.filtered || {}).total_count,
      },
      {
        label: 'Active',
        icon: <VscVmActive />,
        name: 'active',
        value: (meta.filtered || {}).active_count,
      },
      {
        label: 'Removed',
        icon: <MdRemoveFromQueue />,
        name: 'removed',
        value: (meta.filtered || {}).removed_count,
      },
    ]

    return !init ? null : (
      <section className="vertical center">
        <HeadLine title="Extension Installs" className="sz-30 fw-300" />
        <div className="container mt-20 vertical top between">
          <div className="horizon between middle per-100">
            <div className="per-50 horizon left middle" />
            <div className="filter per-50 horizon right">
              <CustomDatePicker
                name="date_range"
                onChange={handleTimeChange}
                // defaultValue={[startTime, endTime]}
                defaultValue={[new Date(from), new Date(to)]}
              />
            </div>
          </div>

          <SummaryData
            className="mt-20"
            chartData={chartData}
            labelData={labelData}
            paramName="status"
            defaultValue="all"
          />
          {params.status === 'removed' && (
            <div className="mt-20 horizon right middle">
              <button
                onClick={handleExport}
                className="btn basic horizon center middle">
                <AiOutlineExport /> Export Report
              </button>
            </div>
          )}

          <table className="list-table mt-20">
            <thead>
              <tr>
                <td>
                  <TableLabel
                    label="INSTALL DATE"
                    field="timestamp"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                {/*<td>*/}
                {/*  <TableLabel*/}
                {/*    label="REMOVED DATE"*/}
                {/*    field="timestamp"*/}
                {/*    meta={meta}*/}
                {/*    store={props.UserStore}*/}
                {/*  />*/}
                {/*</td>*/}
                <td>
                  <TableLabel
                    label="USER ID"
                    field="user_id"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="IP ADDRESS"
                    field="ip_address"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="CITY"
                    field="location_data.city"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="STATE"
                    field="location_data.region"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="COUNTRY"
                    field="location_data.country"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="CAMPAIGN MEDIUM"
                    field="install_referral_data.campaignMedium"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="Referral"
                    field="install_referral_data.campaignTerm"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td>
                  <TableLabel
                    label="LAST VISITED"
                    field="last_visited"
                    meta={meta}
                    store={props.UserStore}
                  />
                </td>
                <td />
              </tr>
            </thead>
            <tbody>
              {state === 'done' &&
                list.map(i => <Row data={i} key={'extensions-' + i.id} />)}
            </tbody>
          </table>
          {state !== 'done' && <Loader />}
        </div>
      </section>
    )
  })
)
