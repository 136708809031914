import {request} from 'helpers'

export class Services {
  static create = async () => null

  static read = async ({time, params = {}}) => {
    // status = ['all', 'active', 'removed'].includes(status) ? status : 'all'
    // time = ['day', 'week', 'month'].includes(time) ? time : 'day'
    return await request.get(`/users/list/`, params)
  }

  static update = async () => null

  static delete = async () => null

  static detail = async (id = null) => {
    return await request.get(`/user/${id}/details/`)
  }

  static selectable = async ({field}) =>
    await request.get(`/users/list/selectable`, {field})
  static searchable = async ({field, q}) =>
    await request.get(`/users/list/searchable`, {field, q})
}
