import moment from 'moment'
import {qs} from './QueryString'
import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

export {request} from './request'
export {qs} from './QueryString'

export const timeToUnix = date => {
  const _date = new Date(date)
  return (
    moment(_date).subtract(_date.getTimezoneOffset(), 'minutes').unix() * 1000
  )
}

export const useDefaultParams = ({
  once = {},
  every = {},
  callback = _ => null,
}) => {
  const history = useHistory()
  const {search} = useLocation()
  const [skip, setSkip] = useState(false)
  const params = qs.parse(search)

  useEffect(() => {
    const historyPush = (params, newParams) => {
      if (JSON.stringify(newParams) !== JSON.stringify(params)) {
        history.push('?' + qs.stringify(newParams))
      }
      callback(true)
    }

    const newParams = {...params}

    Object.entries(every).forEach(([key, val]) => {
      if (!params[key]) newParams[key] = val
    })

    const keys = Object.keys(params)
    // if there are keys, we can skip one time params
    if (skip || keys.length) {
      return historyPush(params, newParams)
    }

    setSkip(true)
    Object.entries(once).forEach(([key, val]) => {
      if (!params[key]) newParams[key] = val
    })

    return historyPush(params, newParams)
  }, [once, every, history, params, skip, setSkip, callback])
}

export const useOutsideAlerter = (ref, action = null) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, action])
}
