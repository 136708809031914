import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdClose} from 'react-icons/md'

export const Modal = inject('UIStore')(
  observer(({UIStore}) => {
    const {id, title, content, actions, show, type} = UIStore.modal

    if (show) document.body.classList.add('overflow-hidden')
    else document.body.classList.remove('overflow-hidden')

    const handleClose = () => {
      UIStore.modalClose()
      document.body.classList.remove('overflow-hidden')
    }

    const eventRemover = () => {
      document.body.removeEventListener('keyup', escClose)
    }

    const escClose = e => {
      if (e.key === 'Escape') {
        handleClose()
        eventRemover()
      }
    }

    document.body.addEventListener('keyup', escClose)

    if (type === 'right')
      return (
        <div
          id={id || 'SideModal'}
          className={'modal-base right ' + (show ? 'show' : '')}
          onClick={handleClose}>
          <div
            className="modal"
            id="scrollBar"
            onClick={e => e.stopPropagation()}>
            <div className="modal-title horizon left middle per-100">
              <MdClose id="closeModalIcon" onClick={handleClose} />
              <h2>{title}</h2>
            </div>
            <div className="modal-content">{content}</div>
          </div>
        </div>
      )
    else if (type === 'empty')
      return (
        <div
          id={id || 'Modal'}
          className={'modal-base empty ' + (show ? 'show' : '')}>
          <div className="modal">
            <div className="modal-content" id="scrollBar">
              {content}
            </div>
            <div className="close-icon">
              <button onClick={handleClose}>
                <MdClose id="closeModalIcon" />
                Close
              </button>
            </div>
            <div className="modal-action horizon right">{actions || ''}</div>
          </div>
        </div>
      )
    else
      return (
        <div
          id={id || 'Modal'}
          className={'modal-base ' + (show ? 'show' : '')}>
          <div className="modal ">
            <div className="modal-title horizon between middle per-100">
              <h2>{title || ''}</h2>
              <MdClose id="closeModalIcon" onClick={handleClose} />
            </div>
            <div className="modal-content" id="scrollBar">
              {content}
            </div>
            <div className="modal-action horizon right">{actions || ''}</div>
          </div>
        </div>
      )
  })
)
