import {computed, makeObservable, observable} from 'mobx'

export class Meta {
  searchable = []
  selectable = []
  sortable = []

  constructor(data) {
    // console.log('history meta model', {data})
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))
  }
}

export class ActionLog {
  domain = ''
  action = ''

  constructor(data) {
    if (data) Object.entries(data).forEach(([k, i]) => (this[k] = i))

    makeObservable(this, {
      domain: observable,
      action: observable,

      id: computed,
    })
  }

  get id() {
    return this['domain'] + this['action']
  }
}
