import {DropDown} from '@evet/library'
import {inject, observer} from 'mobx-react'
import React from 'react'
import {MdPayment} from 'react-icons/all'
import {Link, useParams} from 'react-router-dom'

export const ManagementMenu = inject('UIStore')(
  observer(props => {
    const {country = 'us'} = useParams()

    const toggleMenu = e => {
      if (e) e.stopPropagation()
      props.UIStore.toggleDropDown('ManagementMenu')
      window.addEventListener('click', () => {
        props.UIStore.toggleDropDown('ManagementMenu', false)
      })
    }

    return (
      <React.Fragment>
        <DropDown label="Management">
          <Link to={`/${country}/rules`} onClick={toggleMenu}>
            <MdPayment />
            <span>Rules</span>
          </Link>
        </DropDown>
      </React.Fragment>
    )
  })
)
